import { ChileCode, PeruCode, ColombiaCode } from '../properties';

const validateDni = (dni: string, countryCode: string = 'es_CL') => {
  switch (countryCode) {
    case ChileCode:
      return { isValid: validateRUT(dni), fullDni: dni };
    case ColombiaCode:
      return { isValid: validateDocument(dni), fullDni: dni };
    case PeruCode:
      return validatePeruvianDni(dni);
    default:
      return { isValid: false, fullDni: dni };
  }
};

//#region Chile
const validateRUT = (rut: string): boolean => {
  if (!rut) {
    return false;
  }

  if (!rut.includes('-')) {
    return false;
  }

  try {
    const cleanRut = rut.replace('-', '');
    const checkDigit = [...cleanRut].slice(-1)[0];

    const withoutCheckDigitRUT = cleanRut.slice(0, -1);
    const obtainedCheckDigit = getCheckDigit(withoutCheckDigitRUT);

    if (obtainedCheckDigit === 'K') {
      return checkDigit.toUpperCase() === 'K' || checkDigit === '0';
    }

    return checkDigit.toLowerCase() === obtainedCheckDigit.toLowerCase();
  } catch {
    return false;
  }
};

const getCheckDigit = (dni: string): string => {
  const reversedRUT = [...dni].map((v) => parseInt(v)).reverse();
  let result = 0;

  for (let i = 0, j = 2; i < reversedRUT.length; i++, j < 7 ? j++ : (j = 2)) {
    result += reversedRUT[i] * j;
  }

  return 11 - (result % 11) <= 9 ? String(11 - (result % 11)) : 'K';
};
//#endregion

//#region Colombia
const validateDocument = (document: string): boolean => {
  return /^[0-9]{3,12}/.test(document);
};
//#endregion

//#region Perú
const validatePeruvianDni = (rawDni: string): { isValid: boolean; fullDni: string } => {
  if (!rawDni) {
    return { isValid: false, fullDni: rawDni };
  }

  let dni = rawDni.replace('-', '').trim().toUpperCase();

  // Check if the DNI without the verification digit has the correct length
  if (!dni || dni.length < 8 || dni.length > 9) {
    return { isValid: false, fullDni: dni };
  }

  const multiples = [3, 2, 7, 6, 5, 4, 3, 2];
  const digitsControls = {
    numbers: [6, 7, 8, 9, 0, 1, 1, 2, 3, 4, 5],
    letters: ['K', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
  };

  const dniWithoutVerificationDigit = dni.length === 9 ? dni.substring(0, 8) : dni;
  const providedDigitControl = dni.length === 9 ? dni.substring(dni.length - 1) : null;

  const digitsSum = dniWithoutVerificationDigit.split('').reduce((acc, digit, index) => {
    acc += parseInt(digit, 10) * multiples[index];
    return acc;
  }, 0);

  const key = 11 - (digitsSum % 11);
  const index = key === 11 ? 0 : key;

  let calculatedDigitControl;
  if (/^\d+$/.test(dniWithoutVerificationDigit)) {
    calculatedDigitControl = digitsControls.numbers[index].toString();
  } else {
    calculatedDigitControl = digitsControls.letters[index];
  }

  const fullDni = `${dniWithoutVerificationDigit}${calculatedDigitControl}`;
  const isValid = providedDigitControl ? providedDigitControl === calculatedDigitControl : true;

  return { isValid, fullDni };
};

//#endregion

export default validateDni;
