import React from 'react';
import PropTypes from 'prop-types';
import { Image, Row } from 'react-bootstrap';
import LoadReserveButton from '../buttons/LoadReserveButton';
import { displayServicePriceWithPrefix } from '../../utils/service'

import './LoadServiceItem.scss';

const LoadServiceItem = ({ service, history }) => {
  const { price, imageUrl, sku, brand, shortName, pricePrefix, showPrice } = service;

  return (
    <div className='service-item'>
      <Row>
        <Image className='service-item__image' src={imageUrl} />
      </Row>

      <Row className='service-item__detail'>
        <div className='service-item__brand'>
          {brand}
        </div>

        <h6 className='service-item__name'>
          {shortName || ''}
        </h6>

        {showPrice && (
          <span id='price' className='service-item__price'>
            {displayServicePriceWithPrefix(price, pricePrefix?.prefix)}
          </span>
        )}
      </Row>

      <Row className='service-item__reserve'>
        <LoadReserveButton sku={sku} history={history} />
      </Row>
    </div>
  );
};

LoadServiceItem.propTypes = {
  history: PropTypes.object.isRequired,
};

LoadServiceItem.defaultProps = {};

export default LoadServiceItem;
