import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../errorMessage';
import './styles.scss';

const defaultState = 'select--default';
const focusState = 'select--focus';

const CustomSelect = ({
  label,
  items,
  onSelect,
  id,
  placeHolder,
  storageName,
  defaultSelected = null,
  errorMessage = '',
  displayError = null,
}) => {
  const selectRef = useRef(null);
  const [randomClass, setRandomClass] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [displayOptions, setDisplayOptions] = useState(false);
  const [selectState, setSelectState] = useState(defaultState);
  const [isValid, setIsValid] = useState(true);

  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    if (displayError !== null) {
      setIsValid(!displayError);
    }
  }, [displayError]);

  useEffect(() => {
    if (!defaultSelected) {
      setSelectedItem(null);
    } else {
      const preSelectedItem = items.find(i => i.value === defaultSelected);
      if (preSelectedItem) {
        handleSelect(preSelectedItem);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, defaultSelected]);

  useEffect(() => {
    if (randomClass === '') {
      const rndStr = (Math.random() + 1).toString(36).substring(7);
      setRandomClass(`select_${rndStr}`);
    }
  }, [randomClass]);

  useEffect(() => {
    const handleClick = event => {
      if (displayOptions) {
        const targetClasses = event.target.classList.value;

        if (!targetClasses.includes(randomClass)) {
          setDisplayOptions(false);
          setSelectState(defaultState);
        }
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [displayOptions, randomClass]);

  const handleSelect = item => {
    if (item.value === selectedItem?.value) {
      setDisplayOptions(false);
      setSelectState(defaultState);
      return;
    }

    setSelectedItem(item);
    setTextValue('');

    if (onSelect) {
      onSelect(item.value);
    }

    // To save text in LocalStorage (this is used in the next step. TODO: Improve this)
    if (storageName) {
      localStorage.setItem(storageName, item.text);
    }

    setSelectState(defaultState);
    setDisplayOptions(false);
  };

  const parseItemText = text => {
    if (selectRef.current.clientWidth <= 174 && text.length >= 20) {
      return text.slice(0, 12) + '...';
    } else if (selectRef.current.clientWidth <= 206 && text.length >= 23) {
      return text.slice(0, 17) + '...';
    } else if (selectRef.current.clientWidth <= 248 && text.length >= 28) {
      return text.slice(0, 20) + '...';
    } else if (selectRef.current.clientWidth <= 294 && text.length >= 30) {
      return text.slice(0, 25) + '...';
    } else if (text.length >= 28) {
      return text.slice(0, 26) + '...';
    }

    return text;
  };

  return (
    <div className='cstm-select'>
      <label>{label}</label>

      <span
        id={id}
        value={selectedItem?.value || ''}
        className={`select ${randomClass} ${selectState}`}
        onClick={() => {
          setDisplayOptions(!displayOptions);
          setSelectState(selectState === defaultState ? focusState : defaultState);
        }}
        onBlur={() => setSelectState(defaultState)}
        ref={selectRef}
      >
        <input
          type='text'
          value={textValue}
          onChange={e => setTextValue(e.target.value)}
          placeholder={placeHolder && !selectedItem ? placeHolder : parseItemText(selectedItem?.text || 'Seleccione una opción')}
        />
      </span>
      <div className={`select-opts ${randomClass} ${!displayOptions ? 'select-opts--hide' : ''}`}>
        {items
          ?.filter(i => textValue === '' || i.text.toLowerCase().includes(textValue.toLowerCase()))
          .map((item, index) => (
            <span
              key={index}
              className={`select-opts__item ${randomClass} ${
                item.value === selectedItem?.value ? 'select-opts__item--selected' : ''
              }`}
              onClick={() => handleSelect(item)}
            >
              {item.text}
            </span>
          ))}
      </div>
      <ErrorMessage show={!isValid && !selectedItem} message={errorMessage} />
    </div>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func,
  id: PropTypes.string,
  placeHolder: PropTypes.string,
  storageName: PropTypes.string,
};

export default CustomSelect;
