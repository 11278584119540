import React from 'react';
import useCountry from 'hooks/countryHook';
import './style.scss';

interface iProps {
  description: string;
}

const ServiceDescription = ({ description }: iProps) => {
  const { t } = useCountry();

  const parsedContent = () => {
    return description
      .replace(/\\n/g, '\n')
      .split('\n')
      .map((str, i) => <p key={i}>{str}</p>);
  };

  return (
    <div className='service-description'>
      <span>{t('plp.descriptionText')}</span>
      <div>{parsedContent()}</div>
    </div>
  );
};


export default ServiceDescription;
