import React, { useState, useEffect } from 'react';
import api from '../../../api';
import Menu, { MenuTypes } from '../../menu';
import locationSvg from '../../../img/location.svg';
import { useLocations } from '../../../hooks/useLocations';
import useCountry from '../../../hooks/countryHook';

import './style.scss';

const FilterStoreMobile = ({ handleChange }) => {
  const { t } = useCountry();
  const locations = useLocations('');
  const [displayMenu, setDisplayMenu] = useState(false);
  const [defaultItems, setDefaultItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    if (locations && locations.length) {
      setDefaultItems([
        {
          type: MenuTypes.DROP,
          text: t('pdp.filters-mobile.locationText'),
          values: locations.map((location) => ({
            type: MenuTypes.SELECTABLE,
            name: location.name,
            id: location.idLocation,
            selected: false,
          })),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  useEffect(() => {
    if (defaultItems.length) {
      setMenuItems(defaultItems);
    }
  }, [defaultItems]);

  const applyFilters = (itemList) => {
    let queryParam = '';
    itemList.forEach((item) =>
      item.values.forEach((check) => {
        if (check.selected) {
          const text = item.text === t('pdp.filters-mobile.locationText') ? 'provinceId' : 'storeId';
          queryParam += `${text}=${check.id}&`;
        }
      })
    );

    if (queryParam.length) {
      queryParam = queryParam.substr(0, queryParam.length - 1);
      handleChange('', false, queryParam);
    } else {
      resetFilters();
    }
  };

  const resetFilters = () => {
    setMenuItems(defaultItems);
    handleChange('', true);
  };

  const eachUpdate = async (itemList = []) => {
    const provincesSelected = [];
    const selectedStores = [];
    const indexes = {
      PROVINCES: 0,
      STORES: 1,
    };

    itemList.forEach((list, index) => {
      list.values.forEach((item) => {
        if (item.selected) {
          if (index === indexes.PROVINCES) {
            provincesSelected.push(item);
          } else {
            selectedStores.push(item.id);
          }
        }
      });
    });

    let stores = [];

    if (provincesSelected.length) {
      stores = await getStoresByProvinces(provincesSelected);
    }

    if (stores.length) {
      const storeItems = {
        type: MenuTypes.DROP,
        text: t('pdp.filters-mobile.storeText'),
        values: stores.map((store) => ({
          type: MenuTypes.SELECTABLE,
          name: store.name,
          id: store.idStore,
          selected: selectedStores.includes(store.idStore),
        })),
      };

      setMenuItems((lastItems) => [lastItems[0], storeItems]);
    } else if (menuItems.length === 2) {
      setMenuItems((lastItems) => [lastItems[0]]);
    }
  };

  const getStoresByProvinces = async (locations) => {
    const stores = [];

    const storesPromises = locations.map(async (province) => {
      const savedStore = storeList.find((store) => store.provinceId === province.id);

      if (savedStore) {
        stores.push(savedStore);
      } else {
        try {
          const storeData = await api.getStores(province.id, '');

          if (storeData.respCode === 200 && storeData.data.length) {
            const store = {
              ...storeData.data[0],
              provinceId: province.id,
            };

            setStoreList((lastList) => [...lastList, store]);
            stores.push(store);
          }
        } catch {}
      }
    });

    await Promise.all(storesPromises);

    return stores;
  };

  return (
    <>
      <div className='filter-form' onClick={() => setDisplayMenu(true)}>
        <span className='filter-form__icon'>
          <img src={locationSvg} alt={t('pdp.filters-mobile.title')} />
        </span>
        <span className='filter-form__text'>{t('pdp.filters-mobile.title')}</span>
      </div>

      <Menu
        display={displayMenu}
        title={t('pdp.filters-mobile.title')}
        handleClose={() => setDisplayMenu(false)}
        items={menuItems}
        handleChange={applyFilters}
        eachUpdate={eachUpdate}
      />
    </>
  );
};

export default FilterStoreMobile;
