import React from 'react';
import './styles.scss';

const index = ({ disabled, text, onClick }) => {
  return (
    <button className='survey-button' type='button' disabled={disabled} onClick={onClick}>
      {text}
    </button>
  );
};

export default index;
