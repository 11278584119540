import React, { useState } from 'react';
import { useCleanHTML } from 'hooks/useCleanHtml';
import countryHook from 'hooks/countryHook';
import './styles.scss';

const ServiceDetails = ({ content }) => {
  const { t } = countryHook();
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='service-details'>
      <span
        className={`service-details__title ${expanded ? 'service-details__title--expanded' : ''}`}
        onClick={() => setExpanded(!expanded)}
      >
        {t('plp.detailText')}
      </span>
      <div
        className={`service-details__content ${expanded ? 'service-details__content--expanded' : ''}`}
        dangerouslySetInnerHTML={{ __html: useCleanHTML(content) }}
      ></div>
    </div>
  );
};

export default ServiceDetails;
