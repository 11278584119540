import { useState } from 'react';
import i18n from '../i18n';
import useQuery from './useQuery';
import { ChileCode, ColombiaCode, DEFAULT_COUNTRY, PeruCode, getSavedCountry } from 'properties';

const useCountry = () => {
  const [countryCode, setCountryCode] = useState<string>(DEFAULT_COUNTRY);
  const query = useQuery();

  const getCountry = (): string => {
    let country = countryCode;
    const savedCountry = getSavedCountry(query as any);

    if (savedCountry !== countryCode) {
      setCountryCode(savedCountry);
      country = savedCountry;
    }

    return country;
  };

  const getCountryName = (): string => {
    const code = getCountry();

    switch (code) {
      case PeruCode:
        return 'Peru';
      case ColombiaCode:
        return 'Colombia';
      case ChileCode:
      default:
        return 'Chile';
    }
  };

  const setCountry = (code: string, refresh: boolean = false): void => {
    setCountryCode(code);
    localStorage.setItem('country', code);

    if (refresh) {
      window.location.replace('/mas');
    }
  };

  const setSavedCountry = (): void => {
    const savedCountry = localStorage.getItem('country');

    if (savedCountry && typeof savedCountry === 'string' && savedCountry !== countryCode) {
      setCountry(savedCountry, !window.location.search.includes('lang='));
    }
  };

  const t = (selector: string): string => {
    let text = selector;

    const code = getCountry();

    if (i18n.translations && Object.keys(i18n.translations).includes(code)) {
      let value: Object | string = '';

      selector.split('.').forEach((key) => {
        if (value === '') {
          if (i18n.translations[code]) {
            value = i18n.translations[code][key];
          } else {
            value = key;
          }
        } else if (value) {
          value = value[key];
        }
      });

      text = value?.toString() || selector;
    } else {
      console.warn('Country code not found');
    }

    return text;
  };

  const getLocationName = (): string => {
    const code = getCountry();

    switch (code) {
      case 'es_PE':
        return 'DEPARTAMENTO';
      case 'es_CO':
        return 'DEPARTAMENTO';
      case 'es_CL':
      default:
        return 'REGION';
    }
  };

  const getSubLocationName = (): string => {
    const code = getCountry();

    switch (code) {
      case 'es_PE':
        return 'DISTRITO';
      case 'es_CO':
        return 'CIUDAD';
      case 'es_CL':
      default:
        return 'COMUNA';
    }
  };

  const getPhoneCode = (): string => {
    let country = countryCode;
    const savedCountry = getSavedCountry(query as any);
    let phoneCode: string;

    const countryToPhoneCodeMap = {
      'es_CL': '+56', // Chile
      'es_PE': '+51', // Peru
      'es_CO': '+57', // Colombia
    };

    if (savedCountry !== countryCode) {
      setCountryCode(savedCountry);
      country = savedCountry;
    }

    phoneCode = countryToPhoneCodeMap[country] || '';
    return phoneCode;
  };

  return {
    getCountry,
    getCountryName,
    getLocationName,
    getSubLocationName,
    setCountry,
    setSavedCountry,
    t,
    getPhoneCode,
  };
};

export default useCountry;
