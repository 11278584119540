import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'

const ServiceImage = ({ url, alt }) => (
  <img className='service-img' src={url} alt={alt} />
)

ServiceImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default ServiceImage
