import React, { useState } from 'react';
import logo from 'img/Isotipo.png';
import useCountry from 'hooks/countryHook';
import Menu, { MenuItem, MenuTypes } from '../menu';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles.scss';

const NavBar = () => {
  const showCountrySelector = (process.env.REACT_APP_SHOW_COUNTRY_SELECTOR || false) === 'true';
  const { t, getCountry, setCountry } = useCountry();
  const [displayMenu, setDisplayMenu] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>(getCountry());
  const items: MenuItem[] = [
    {
      type: MenuTypes.LINK,
      url: t('navbar.all-services-url'),
      text: t('navbar.all-services-text'),
      customClass: 'all-services-url',
      values: [],
    },
    {
      type: MenuTypes.LINK,
      url: t('navbar.store-schedules-url'),
      text: t('navbar.store-schedules-text'),
      values: [],
    },
  ];

  const changeCountry = (countryCode: string): void => {
    setCountry(countryCode, true);
    setSelectedCountry(countryCode);
  };

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-toggler' onClick={() => setDisplayMenu(true)}>
          <FontAwesomeIcon icon={faBars} size='lg' />
        </div>
        <a href='/'>
          <img src={logo} alt='Falabella' />
        </a>
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <a href={item.url} target='_blank' rel='noopener noreferrer'>
                {item.text}
              </a>
            </li>
          ))}

          {showCountrySelector && (
            <li>
              <span>
                País: &nbsp;
                <select
                  data-testid='country-selector'
                  value={selectedCountry}
                  onChange={(ev) => changeCountry(ev.target.value)}
                >
                  <option value='es_CL'>Chile</option>
                  <option value='es_PE'>Perú</option>
                  <option value='es_CO'>Colombia</option>
                </select>
              </span>
            </li>
          )}
        </ul>
      </nav>
      <Menu
        display={displayMenu}
        title={t('navbar.menu-title')}
        handleClose={() => setDisplayMenu(false)}
        items={items}
      />
    </>
  );
};

export default NavBar;
