import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getRandom } from '../../utils/utils';
import ErrorMessage from '../inputs/errorMessage';

import './Blocks.scss';

const Blocks = ({ availabilityInfo, selectedDate, setSelectedBlock, displayError, dailyDisplayHours }) => {
  const [currentBlock, setCurrentBlock] = useState({});
  const [blocksAM, setBlocksAM] = useState([]);
  const [blocksPM, setBlocksPM] = useState([]);
  const [blocksDay, setBlocksDay] = useState([]);
  const TimeUnit = {
    Hour: 'H',
    Daily: 'D',
    Weekly: 'W',
  };

  useEffect(() => {
    setSelectedBlock(currentBlock);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBlock]);

  useEffect(() => {
    const clearBlocks = () => {
      setBlocksAM([]);
      setBlocksPM([]);
      setBlocksDay([]);
    };

    const orderBlocks = (availabilityBlocks, timeUnit) => {
      let parsedBlocks = [];

      if (timeUnit === TimeUnit.Hour) {
        parsedBlocks = availabilityBlocks.sort((blockA, blockB) => new Date(blockA.from) - new Date(blockB.from));
      }
      else {
        parsedBlocks = availabilityBlocks;
      }

      return parsedBlocks;
    }

    const parseBlocks = (availabilityBlocks, timeUnit) => {
      const formatedDate = selectedDate.toISOString().split('T')[0];
      const blocks = availabilityBlocks.filter(d => d.from.slice(0, 10) === formatedDate);

      if (timeUnit === TimeUnit.Hour) {
        const tmpBlockAm = [];
        const tmpBlockPM = [];

        blocks.forEach(block => {
          const from = moment(block.from);
          const to = moment(block.to);
          const blockText = `${from.format('HH:mm')} a ${to.format('HH:mm')}`;
          const key = `hour_block_${getRandom(500, 1)}`;

          const parsedBlock = {
            key: key,
            text: blockText,
            handleClick: () =>
              setCurrentBlock({
                ...block,
                text: blockText,
              }),
          };

          if (from.format('A') === 'AM') {
            tmpBlockAm.push(parsedBlock);
          } else {
            tmpBlockPM.push(parsedBlock);
          }
        });

        if (tmpBlockAm.length) {
          setBlocksAM(tmpBlockAm);
        }

        if (tmpBlockPM.length) {
          setBlocksPM(tmpBlockPM);
        }
      } else if (timeUnit === TimeUnit.Daily) {
        const tmpBlock = [];
        let isOneDay = false;

        blocks.forEach((block, i) => {
          const from = moment(block.from);
          const to = moment(block.to);
          const key = `daily_block_${getRandom(200, 1)}`;

          let blockText = '';

          if (i === 0) {
            const diff = (to - from) / (1000 * 60 * 60);
            if (diff < 24) {
              isOneDay = true;
            }
          }

          if (dailyDisplayHours) {
            const displayKeys = Object.keys(dailyDisplayHours);
            const timeCurrentDay = dailyDisplayHours[displayKeys[from.format('d') - 1]];

            if (timeCurrentDay) {
              const hours = timeCurrentDay.split('|');

              if (hours.length === 2) {
                blockText = `${hours[0]} a ${hours[1]} hrs`;
              }
            }
          }

          if (!blockText) {
            if (isOneDay) {
              blockText = `de ${from.format('HH:mm')} a ${to.format('HH:mm')} hrs`;
            } else {
              blockText = `${from.format('DD-MM')} a ${to.format('DD-MM')}`;
            }
          }

          tmpBlock.push({
            key: key,
            text: blockText,
            handleClick: () =>
              setCurrentBlock({
                ...block,
                text: blockText,
              }),
          });
        });

        if (tmpBlock.length) {
          setBlocksDay(tmpBlock);

          if (tmpBlock.length === 1) {
            tmpBlock[0].handleClick();
          }
        }
      } else if (timeUnit === TimeUnit.Weekly) {
        const tmpBlock = [];

        blocks.forEach(block => {
          const from = moment(block.from);
          const to = moment(block.to);
          const key = `weekly_block_${getRandom(200, 1)}`;
          const blockText = `${from.format('DD-MM')} a ${to.format('DD-MM')}`;

          tmpBlock.push({
            key: key,
            text: blockText,
            handleClick: () =>
              setCurrentBlock({
                ...block,
                text: blockText,
              }),
          });
        });

        if (tmpBlock.length) {
          setBlocksDay(tmpBlock);

          if (tmpBlock.length === 1) {
            tmpBlock[0].handleClick();
          }
        }
      }
    };

    if (selectedDate && Object.keys(availabilityInfo).length && availabilityInfo.availabilityBlocks.length) {
      setCurrentBlock({});
      clearBlocks();
      const orderedBlocks = orderBlocks(availabilityInfo.availabilityBlocks, availabilityInfo.timeUnit);

      parseBlocks(orderedBlocks, availabilityInfo.timeUnit);
    } else {
      setCurrentBlock({});
      clearBlocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityInfo, selectedDate]);

  return (
    <div className='calendar-blocks'>
      <ErrorMessage show={displayError && !currentBlock.from} message='Debes seleccionar un horario' />

      {!!blocksAM.length && (
        <div className='calendar-blocks__section'>
          <div className='calendar-blocks__title'>Mañana</div>

          {blocksAM.map(block => (
            <button
              key={block.key}
              className={`block-button ${currentBlock?.text === block.text ? 'block-button--selected' : ''}`}
              onClick={block.handleClick}
            >
              {block.text}
            </button>
          ))}
        </div>
      )}

      {!!blocksPM.length && (
        <div className='calendar-blocks__section'>
          <div className='calendar-blocks__title'>Tarde</div>

          {blocksPM.map(block => (
            <button
              key={block.key}
              className={`block-button ${currentBlock?.text === block.text ? 'block-button--selected' : ''}`}
              onClick={block.handleClick}
            >
              {block.text}
            </button>
          ))}
        </div>
      )}

      {!!blocksDay.length && (
        <div className='daily-blocks'>
          <div className='daily-blocks__title'>El servicio se realizara dentro del siguiente rango horario:</div>

          {blocksDay.map(block => (
            <button
              key={block.key}
              className={`block-button ${currentBlock?.text === block.text ? 'block-button--selected' : ''}`}
              onClick={block.handleClick}
            >
              {block.text}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blocks;
