import StylingLogo from '../img/survey/styling/homeLogo.svg';
import StylingBodyA from '../img/survey/styling/bodyA.svg';
import StylingBodyB from '../img/survey/styling/bodyB.svg';
import StylingBodyC from '../img/survey/styling/bodyC.svg';

import StylingBrightColor from '../img/survey/styling/brightColors.svg';
import StylingPastelColor from '../img/survey/styling/pastelColor.svg';
import StylingNeutralColor from '../img/survey/styling/neutralColor.svg';
import StylingLinesOrStripesColor from '../img/survey/styling/linesOrStripes.svg';
import StylingAnimalPrintColor from '../img/survey/styling/animalPrint.svg';
import StylingFlowersColor from '../img/survey/styling/flowersPreference.svg';
import StylingShinyOrSequinsColor from '../img/survey/styling/shinyOrSequins.png';

//#region Forms
const shirtsSizes = [
  {
    type: 'tiny-check',
    label: 'XXS',
    value: 'XXS',
    onlyFor: ['F'],
  },
  {
    type: 'tiny-check',
    label: 'XS',
    value: 'XS',
    onlyFor: ['F'],
  },
  {
    type: 'tiny-check',
    label: 'S',
    value: 'S',
    onlyFor: ['F', 'M'],
  },
  {
    type: 'tiny-check',
    label: 'M',
    value: 'M',
    onlyFor: ['F', 'M'],
  },
  {
    type: 'tiny-check',
    label: 'L',
    value: 'L',
    onlyFor: ['F', 'M'],
  },
  {
    type: 'tiny-check',
    label: 'XL',
    value: 'XL',
    onlyFor: ['F', 'M'],
  },
  {
    type: 'tiny-check',
    label: 'XXL',
    value: 'XXL',
    onlyFor: ['F', 'M'],
  },
  {
    type: 'tiny-check',
    label: '3XL',
    value: '3XL',
    onlyFor: ['F', 'M'],
  },
  {
    type: 'tiny-check',
    label: '4XL',
    value: '4XL',
    onlyFor: ['F', 'M'],
  },
  {
    type: 'tiny-check',
    label: 'No lo tengo claro',
    value: 'No lo tengo claro',
    onlyFor: ['F', 'M'],
  },
];

const pants = [
  {
    type: 'tiny-check',
    label: 'XXS',
    value: 'XXS',
    onlyFor: ['F', 'O'],
  },
  {
    type: 'tiny-check',
    label: 'XS',
    value: 'XS',
    onlyFor: ['F', 'O'],
  },
  {
    type: 'tiny-check',
    label: 'S',
    value: 'S',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'tiny-check',
    label: 'M',
    value: 'M',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'tiny-check',
    label: 'L',
    value: 'L',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'tiny-check',
    label: 'XL',
    value: 'XL',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'tiny-check',
    label: 'XXL',
    value: 'XXL',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'tiny-check',
    label: '3XL',
    value: '3XL',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'tiny-check',
    label: '4XL',
    value: '4XL',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'tiny-check',
    label: '34',
    value: '34',
    onlyFor: ['F'],
  },
  {
    type: 'tiny-check',
    label: '36',
    value: '36',
    onlyFor: ['F'],
  },
  {
    type: 'tiny-check',
    label: '38',
    value: '38',
    onlyFor: ['F'],
  },
  {
    type: 'tiny-check',
    label: '40',
    value: '40',
    onlyFor: ['M', 'F'],
  },
  {
    type: 'tiny-check',
    label: '42',
    value: '42',
    onlyFor: ['M', 'F'],
  },
  {
    type: 'tiny-check',
    label: '44',
    value: '44',
    onlyFor: ['M', 'F'],
  },
  {
    type: 'tiny-check',
    label: '46',
    value: '46',
    onlyFor: ['M', 'F'],
  },
  {
    type: 'tiny-check',
    label: '48',
    value: '48',
    onlyFor: ['M', 'F'],
  },
  {
    type: 'tiny-check',
    label: '50',
    value: '50',
    onlyFor: ['M', 'F'],
  },
  {
    type: 'tiny-check',
    label: '52',
    value: '52',
    onlyFor: ['M', 'F'],
  },
  {
    type: 'tiny-check',
    label: '54',
    value: '54',
    onlyFor: ['M'],
  },
  {
    type: 'tiny-check',
    label: '56',
    value: '56',
    onlyFor: ['M'],
  },
  {
    type: 'tiny-check',
    label: '58',
    value: '58',
    onlyFor: ['M'],
  },
  {
    type: 'tiny-check',
    label: 'No lo tengo claro',
    value: 'No lo tengo claro',
    onlyFor: ['F', 'M', 'O'],
  },
];

const brands = [
  {
    type: 'check',
    label: 'Basement',
    value: 'Basement',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'check',
    label: 'Bearcliff',
    value: 'Bearcliff',
    onlyFor: ['M', 'O'],
  },
  {
    type: 'check',
    label: 'Sybilla',
    value: 'Sybilla',
    onlyFor: ['F', 'O'],
  },
  {
    type: 'check',
    label: 'Mossimo',
    value: 'Mossimo',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'check',
    label: 'Americanino',
    value: 'Americanino',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'check',
    label: 'University',
    value: 'University',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'check',
    label: 'Mango',
    value: 'Mango',
    onlyFor: ['F', 'M', 'O'],
  },
  {
    type: 'check',
    label: 'Mango Man',
    value: 'Mango Man',
    onlyFor: ['M', 'O'],
  },
  {
    type: 'check',
    label: 'La Martina',
    value: 'La Martina',
    onlyFor: ['M', 'O'],
  },
  {
    type: 'check',
    label: 'Stefano Cocci',
    value: 'Stefano Cocci',
    onlyFor: ['F', 'O'],
  },
  {
    type: 'check',
    label: 'Ninguna de las anteriores',
    value: 'Ninguna de las anteriores',
    onlyFor: ['F', 'M', 'O'],
  },
];

const stylingForm = {
  steps: [
    {
      position: 1,
      title: '¿Con qué género te identificas?',
      subTitle: '',
      information: '',
      showButton: false,
      disabledButton: true,
      sections: [
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          id: 'gender',
          options: [
            {
              type: 'radio',
              label: 'Femenino',
              value: 'F',
              regex: '',
            },
            {
              type: 'radio',
              label: 'Masculino',
              value: 'M',
              regex: '',
            },
            {
              type: 'radio',
              label: 'Prefiero no decir',
              value: 'O',
              regex: '',
            },
          ],
        },
      ],
      onValidate: () => {},
    },
    {
      position: 2,
      title: '¿Qué edad tienes?',
      subTitle: '',
      information: '',
      showButton: true,
      disabledButton: true,
      sections: [
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          id: 'age',
          options: [
            {
              type: 'input',
              label: 'Tu edad',
              regex: /[0-9]{1,2}/g,
            },
          ],
        },
      ],
      onValidate: (value) => !isNaN(parseInt(value)) && value < 100 && value > 0,
    },
    {
      position: 3,
      title: '¿Por qué tomarás esta asesoría?',
      subTitle: 'Puedes escoger más de un motivo.',
      information: '',
      showButton: true,
      disabledButton: true,
      sections: [
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          id: 'asesoryMotive',
          options: [
            {
              type: 'check',
              label: 'Renovación de closet',
              value: 'renovación de closet',
              regex: '',
            },
            {
              type: 'check',
              label: 'Ocasión especial',
              value: 'ocasión especial',
              regex: '',
            },
            {
              type: 'check',
              label: 'Vacaciones',
              value: 'vacaciones',
              regex: '',
            },
            {
              type: 'check',
              label: 'Ropa para la oficina',
              value: 'ropa para la oficina',
              regex: '',
            },
            {
              type: 'check',
              label: 'Otro',
              value: 'otro',
              regex: '',
            },
          ],
        },
      ],
      onValidate: (values) => values && !!values.length,
    },
    {
      position: 4,
      title: 'Cuéntanos más detalles sobre lo que buscas con esta asesoría',
      subTitle: '',
      information: '',
      showButton: true,
      disabledButton: false,
      sections: [
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          id: 'asesoryDetail',
          options: [
            {
              type: 'textarea',
              label: '',
              placeholder: 'Puedes dejar un comentario o link de referencia. ',
              value: '',
              regex: '',
            },
          ],
          value: null,
        },
      ],
      onValidate: () => {},
    },
    {
      position: 5,
      title: '¿Cuál de estos cuerpo es más similar al tuyo?',
      subTitle:
        'La selección es solo referencial y el día de la cita tu personal shopper  ajustará sus recomendaciones a tu cuerpo. ',
      information: '',
      showButton: true,
      disabledButton: true,
      customClass: ['wrapper-xl', 'centered'],
      sections: [
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          id: 'bodyType',
          customSectionClass: ['section-flex'],
          options: [
            {
              type: 'customStylingRadio',
              label: 'Caderas más anchas que los hombros',
              value: 'Caderas más anchas que los hombros',
              image: StylingBodyA,
            },
            {
              type: 'customStylingRadio',
              label: 'Caderas del mismo ancho que los hombros',
              value: 'Caderas del mismo ancho que los hombros',
              image: StylingBodyB,
            },
            {
              type: 'customStylingRadio',
              label: 'Hombros más anchos que las caderas',
              value: 'Hombros más anchos que las caderas',
              image: StylingBodyC,
            },
          ],
        },
      ],
      onValidate: () => true,
    },
    {
      position: 6,
      title: '¿Qué talla usas normalmente?',
      subTitle: 'Puedes elegir más de una talla',
      information:
        'La selección es solo referencial y el día de la cita tu Personal Shopper ajustará sus recomendaciones .',
      showButton: true,
      disabledButton: true,
      customClass: ['wrapper-xl'],
      sections: [
        {
          position: 1,
          title: 'Poleras y/o blusas',
          subTitle: '',
          type: '',
          onlyFor: ['F'],
          id: 'shirtSize',
          options: shirtsSizes.filter((s) => s.onlyFor.includes('F')),
        },
        {
          position: 1,
          title: 'Poleras',
          subTitle: '',
          type: '',
          onlyFor: ['M'],
          id: 'shirtSize',
          options: shirtsSizes.filter((s) => s.onlyFor.includes('M')),
        },
        {
          position: 1,
          title: 'Poleras y/o blusas',
          subTitle: '',
          type: '',
          onlyFor: ['O'],
          id: 'shirtSize',
          options: shirtsSizes,
        },
        {
          position: 2,
          title: 'Vestidos',
          subTitle: '',
          type: '',
          onlyFor: ['F', 'O'],
          id: 'dressSize',
          options: shirtsSizes.filter((s) => s.onlyFor.includes('F')),
        },
        {
          position: 3,
          title: 'Pantalones y/o faldas',
          subTitle: '',
          type: '',
          onlyFor: ['F'],
          id: 'pantSize',
          options: pants.filter((s) => s.onlyFor.includes('F')),
        },
        {
          position: 3,
          title: 'Pantalones',
          subTitle: '',
          type: '',
          onlyFor: ['M'],
          id: 'pantSize',
          options: pants.filter((s) => s.onlyFor.includes('M')),
        },
        {
          position: 3,
          title: 'Pantalones y/o faldas',
          subTitle: '',
          type: '',
          onlyFor: ['O'],
          id: 'pantSize',
          options: pants.filter((s) => s.onlyFor.includes('O')),
        },
      ],
      onValidate: (savedValues) => {
        if (!savedValues || !Object.keys(savedValues).includes('gender')) {
          return false;
        }

        if (savedValues.gender === 'F' || savedValues.gender === 'O') {
          if (!Object.keys(savedValues).includes('dressSize') || !savedValues.dressSize) {
            return false;
          }
        }

        if (!Object.keys(savedValues).includes('shirtSize') || !savedValues.shirtSize) {
          return false;
        }

        if (!Object.keys(savedValues).includes('pantSize') || !savedValues.pantSize) {
          return false;
        }

        return true;
      },
    },
    {
      position: 7,
      title: '¿Tu estilo es siempre a la moda?',
      subTitle: '',
      information: '',
      showButton: false,
      disabledButton: true,
      sections: [
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          id: 'feelAboutTendencies',
          options: [
            {
              type: 'radio',
              label: 'Me gusta vestir a la moda',
              value: 'me gusta vestir a la moda',
              regex: '',
            },
            {
              type: 'radio',
              label: 'A veces visto a la moda',
              value: 'a veces visto a la moda',
              regex: '',
            },
            {
              type: 'radio',
              label: 'No me interesa vestir a la moda',
              value: 'no me interesa vestir a la moda',
              regex: '',
            },
          ],
        },
      ],
      onValidate: () => {},
    },
    {
      position: 8,
      title: 'Selecciona tus preferencias en colores y estampados',
      subTitle: '',
      information: '',
      customClass: ['wrapper-xl'],
      showButton: true,
      disabledButton: true,
      sections: [
        {
          position: 1,
          title: 'Colores brillantes',
          image: StylingBrightColor,
          subTitle: '',
          type: '',
          id: 'brightColorsPreference',
          options: [
            {
              type: 'tiny-radio',
              label: 'Me gusta',
              value: 'Me gusta',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'Depende',
              value: 'Depende',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'No me gusta',
              value: 'No me gusta',
              regex: '',
            },
          ],
        },
        {
          position: 2,
          title: 'Colores Pasteles',
          image: StylingPastelColor,
          subTitle: '',
          type: '',
          id: 'pastelColorsPreference',
          options: [
            {
              type: 'tiny-radio',
              label: 'Me gusta',
              value: 'Me gusta',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'Depende',
              value: 'Depende',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'No me gusta',
              value: 'No me gusta',
              regex: '',
            },
          ],
        },
        {
          position: 3,
          title: 'Colores neutros',
          image: StylingNeutralColor,
          subTitle: '',
          type: '',
          id: 'neutralColorsPreference',
          options: [
            {
              type: 'tiny-radio',
              label: 'Me gusta',
              value: 'Me gusta',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'Depende',
              value: 'Depende',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'No me gusta',
              value: 'No me gusta',
              regex: '',
            },
          ],
        },
        {
          position: 4,
          title: 'Líneas o rayas',
          image: StylingLinesOrStripesColor,
          subTitle: '',
          type: '',
          id: 'linesOrStripesPreference',
          options: [
            {
              type: 'tiny-radio',
              label: 'Me gusta',
              value: 'Me gusta',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'Depende',
              value: 'Depende',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'No me gusta',
              value: 'No me gusta',
              regex: '',
            },
          ],
        },
        {
          position: 5,
          title: 'Animal print',
          image: StylingAnimalPrintColor,
          subTitle: '',
          type: '',
          id: 'animalPrintPreference',
          options: [
            {
              type: 'tiny-radio',
              label: 'Me gusta',
              value: 'Me gusta',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'Depende',
              value: 'Depende',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'No me gusta',
              value: 'No me gusta',
              regex: '',
            },
          ],
        },
        {
          position: 6,
          title: 'Florales',
          image: StylingFlowersColor,
          subTitle: '',
          type: '',
          id: 'flowersPreference',
          options: [
            {
              type: 'tiny-radio',
              label: 'Me gusta',
              value: 'Me gusta',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'Depende',
              value: 'Depende',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'No me gusta',
              value: 'No me gusta',
              regex: '',
            },
          ],
        },
        {
          position: 7,
          title: 'Brillos y lentejuelas',
          image: StylingShinyOrSequinsColor,
          subTitle: '',
          type: '',
          id: 'shinyOrSequinsPreference',
          options: [
            {
              type: 'tiny-radio',
              label: 'Me gusta',
              value: 'Me gusta',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'Depende',
              value: 'Depende',
              regex: '',
            },
            {
              type: 'tiny-radio',
              label: 'No me gusta',
              value: 'No me gusta',
              regex: '',
            },
          ],
        },
      ],
      onValidate: (values) => {
        let isValid = true;
        const requiredEntries = [
          'brightColorsPreference',
          'pastelColorsPreference',
          'neutralColorsPreference',
          'linesOrStripesPreference',
          'animalPrintPreference',
          'flowersPreference',
          'shinyOrSequinsPreference',
        ];

        requiredEntries.forEach(entry => {
          if (!Object.keys(values).includes(entry) || !values[entry]) {
            isValid = false;
          }
        });

        return isValid;
      },
    },
    {
      position: 9,
      title: '¿Hay alguna parte de cuerpo que te interese destacar? ¿Alguna que no?',
      subTitle: '',
      information: '',
      showButton: true,
      disabledButton: false,
      customClass: ['wrapper-xl'],
      sections: [
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          id: 'bodyFocusDetail',
          options: [
            {
              type: 'textarea',
              label: '',
              placeholder: 'Cuentános todos los detallles, así la asesoría será mucho más personalizada.',
              value: '',
              regex: '',
            },
          ],
        },
      ],
      onValidate: () => {},
    },
    {
      position: 10,
      title: 'Seleciona tus marcas Falabella favoritas',
      subTitle:
        'Esto nos ayudará a conocer tus gustos. Sin embargo, tu Personal Shopper hará una selección considerando todas las marcas disponibles en la tienda',
      information: '',
      showButton: true,
      disabledButton: true,
      customClass: ['wrapper-xl'],
      sections: [
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          onlyFor: ['F'],
          id: 'favoriteBrands',
          options: brands.filter((s) => s.onlyFor.includes('F')),
        },
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          onlyFor: ['M'],
          id: 'favoriteBrands',
          options: brands.filter((s) => s.onlyFor.includes('M')),
        },
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          onlyFor: ['O'],
          id: 'favoriteBrands',
          options: brands.filter((s) => s.onlyFor.includes('O')),
        },
      ],
      onValidate: (values) => values && values.length >= 1,
    },
    {
      position: 11,
      title:
        'Por último, ¿Hay otras marcas que te gustaría que tu Personal Shopper use como referencia para la asesoría?',
      subTitle: 'Esto nos ayudará para acercarnos aún más a lo que estás buscando.',
      information: '',
      showButton: true,
      disabledButton: false,
      customClass: ['wrapper-xl'],
      sections: [
        {
          position: 1,
          title: '',
          subTitle: '',
          type: '',
          id: 'otherBrandsForAsesory',
          options: [
            {
              type: 'textarea',
              label: '',
              placeholder: 'Menciona tus marcas favoritas. Pueden estar disponibles o no en nuestra tienda.',
              value: '',
              regex: '',
            },
          ],
        },
      ],
      onValidate: () => {},
    },
  ],
};
//#endregion

//#region Home
const stylingFormHome = {
  title: 'Formulario de Estilismo',
  subTitle: 'Responde estas preguntas para conocerte mejor y entregarte un servicio personalizado.',
  logo: StylingLogo,
};
//#endregion

export const getFormById = (id) => {
  switch (id) {
    case 'styling':
      return stylingForm;
    default:
      return null;
  }
};

export const getFormHome = (id) => {
  switch (id) {
    case 'styling':
      return stylingFormHome;
    default:
      return null;
  }
};
