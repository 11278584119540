import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import arrowup from '../../img/iconarrowup.svg';
import { Accordion, Card } from 'react-bootstrap';
import uncheck from '../../img/squareUncheck_2.png';
import imgSidebar from '../../img/Sidebarbanner.png';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../styles/Falabella.css';

const LoadFilterForm = (props) => {
  const { search } = props.history.location;
  const indexFilter = { level: '', valor: '' };
  const { onChangeFiltro, filters1 } = props;
  const { filters } = filters1;
  const [defaultActive, setDefaultActive] = useState();
  const [searchResult, setSearchResult] = useState(filters);
  const [check, setCheck] = useState(false);
  const [buttonFilter, setButtonFilter] = useState();

  const handleArrow = (i) => {
    let img = document.getElementById('card-img' + i);

    if (img && img.getAttribute('data-arrow') === 'true') {
      img.dataset.arrow = 'false';
      img.src = arrowup;
    } else {
      img.dataset.arrow = 'true';
      img.src = './img/icondropdown.svg';
    }
  };

  useEffect(() => {
    setSearchResult(cleanFilters(filters));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, check]);

  const cleanFilters = (filters) => {
    const queryParams = search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, [key, value]) => ({
      ...obj,
      [key]: [
        ...obj[key] || [],
        decodeURIComponent(value || '')
      ]
    }), {});

    const parsedFilters = filters.map(type => {
      if (!Object.keys(queryParams).includes(type.name)) {
        type.values = type.values.map(v => {
          v.selected = false;
          return v;
        })
      }
      else {
        type.values = type.values.map((v => {
          if (queryParams[type.name] && queryParams[type.name].includes(v.id)) {
            v.selected = true;
          }

          return v;
        }));
      }

      return type;
    })


    return parsedFilters;
  }

  const Lista = ({ nombre, level }) => {
    return (
      <ul className='itemScroll'>
        {searchResult[level].values
          .filter((f) => {
            if (
              f.name
                .toLowerCase()
                .includes(indexFilter.valor.toLowerCase()) &&
              indexFilter.level === level
            ) {
              return f;
            }
            if (indexFilter.level !== level) {
              return f;
            } 
            return false;
          })
          .map((f) => {
            return (
              <li key={f.id}>
                <label className='checkbox aligItem'>
                  {f.selected ? (
                    <span className='iconoCheck'>
                      <FontAwesomeIcon icon={faCheck} color='green' size='lg' />
                    </span>
                  ) : (
                    <span>
                      <img
                        className='iconoUnCheck'
                        alt='uncheckImage'
                        src={uncheck}
                      ></img>
                    </span>
                  )}
                  <input
                    type='checkbox'
                    onClick={(e) => {
                      setCheck(!check);
                      setDefaultActive(level);
                      onChangeFiltro(e.target, false);
                    }}
                    id={f.id}
                    tag={nombre}
                    title={nombre}
                    name={'filterform' + f.id}
                    defaultChecked={f.selected ? 'checked' : ''}
                    style={{ display: 'none' }}
                  />
                  <label
                    htmlFor={f.key}
                    className='filterMargin'
                  />
                  <span className='etiquetasFalabella'>
                    {f.name.toUpperCase()}
                  </span>
                  <span className='label-count'>&nbsp;&nbsp;</span>
                </label>
              </li>
            );
          })}
      </ul>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const FiltroButton = ({ nivel, _index }) => {
    return nivel.values.map((d, ind) => {
      if (d.selected) {
        const rng = Math.floor(Math.random() * 150);

        return (
          <button
            onClick={() => {
              document.getElementById(d.id).click();
              setCheck(!check);
              setDefaultActive(_index);
            }}
            key={`button_filter_${rng}`}
            id={`botton-filter-${ind}`}
            style={{ outline: "none" }}
            type="button"
            className="buttonFilterSelected"
          >
            {d.name}
            <i key={"icono" + ind + _index} className="buttonDeselectFilter">
              <span key={"span-icono" + ind + _index}>X</span>
            </i>
          </button>
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (document.getElementById("containerButtons")) {
      setButtonFilter(
        document.getElementById("containerButtons").hasChildNodes()
      );
    }
  }, [FiltroButton]);

  if (searchResult?.length) {
    return (
      <>
        <section style={buttonFilter ? {} : { display: "none" }}>
          <section>
            <section className="categoty-title-container titleFilterSelected">
              <span className="title-filter">Filtros seleccionados</span>
              <button
                style={{ outline: "none", color: '#333333' }}
                onClick={() => {
                  onChangeFiltro("", true);
                }}
                type="button"
                className="buttonRemoveAllFilter"
                data-testid="buttonRemoveAllFilter"
              >
                <span style={{ outline: "none" }}>
                  <i style={{ outline: "none" }}>X</i>
                </span>
              </button>
            </section>

            <section id="containerButtons" className="containerButtons">
              {searchResult.map((a, i) => {
                const rng = Math.floor(Math.random() * 10);
                return (
                  <FiltroButton key={`${a.name}_${i}_${rng}`} nivel={a} index={i} />
                );
              })}
            </section>
          </section>
        </section>

        <section className="categoty-title-container">
          <label className="l1category">
            <span className="title-filter-item">Filtros disponibles</span>
          </label>
        </section>
        {searchResult.map((data, i) => {
          return (
            <Accordion key={"card-id-" + i} defaultActiveKey={defaultActive}>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    onClick={() => {
                      handleArrow(i);
                    }}
                    style={{ outline: "none" }}
                    className="card-button"
                    eventKey={i}
                    id={"card-button-" + i}
                    data-testid='accordion-toggle-button'
                  >
                    <span className="title-filter-item">
                      {data.name}
                    </span>
                    <span id={"card-header" + i} className="arrow-down-right">
                      <img
                        id={"card-img" + i}
                        data-arrow={"true"}
                        src={"./img/icondropdown.svg"}
                        width="20px"
                        alt="arrowDown"
                      />
                    </span>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={i}>
                  <Card.Body>
                    <Lista nombre={data.name} level={i} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          );
        })}
      </>
    );
  }
  else {
    return (<img id="Sidebarbanner" src={imgSidebar} alt="Sidebarbanner"></img>);
  }
};

LoadFilterForm.propTypes = {
  filters: PropTypes.array.isRequired,
  onChangeFiltro: PropTypes.func,
};
LoadFilterForm.defaultProps = {
  filters: [],
};

export default LoadFilterForm;
