import es_CL from './es_CL';
import es_PE from './es_PE';
import es_CO from './es_CO';

const defaultLang = 'es_CL';

const i18n = {
  translations: {
    es_CL: es_CL.i18n,
    es_PE: es_PE.i18n,
    es_CO: es_CO.i18n,
  },
  defaultLang: defaultLang,
  useBrowserDefault: false,
};

export default i18n;
