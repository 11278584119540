import React from 'react';
import { Spinner, Modal } from "react-bootstrap";
import "../../styles/Falabella.css";

const Loader = ({loading}) => 
     (
        <Modal
        show={loading}
        backdrop="static"
        keyboard={false}
        className="loading"
      >
        <Modal.Body>

          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        
        </Modal.Body>
      </Modal>
    );
 
  
  export default Loader;