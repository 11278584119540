import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import uncheck from '../../img/squareUncheck_2.png';
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SelectableItem.scss'

const SelectableItem = ({ item, handleCheck }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(item.selected);
    }, [item.selected])

    return (
        <li className='selectable-item'>
            <Form.Check type='checkbox' id={item.id} className='selectable-item__container'>
                <Form.Check.Input
                    className='selectable-item__checkbox'
                    type='checkbox'
                    style={{ display: 'none' }}
                    onClick={() => {
                        setChecked(!checked);
                        handleCheck && handleCheck(item.id, !checked);
                    }}
                />
                <Form.Check.Label className='selectable-item__label'>
                    <span className='selectable-item__label-icon'>
                        {checked ? <FontAwesomeIcon icon={faCheck} className='selectable-item__check-icon' />
                        : <img className="iconoUnCheck" alt="uncheckImage" src={uncheck} />}
                    </span>
                    
                    <span>
                        {item.name}
                    </span>
                </Form.Check.Label>
            </Form.Check>
        </li>
    )
}

export default SelectableItem
