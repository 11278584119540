import React, { useState, useEffect } from 'react';
import ErrorMessage from '../errorMessage';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './styles.scss';

const renderTooltip = (props, message) => (
  <Tooltip id='button-tooltip' {...props}>
    {message}
  </Tooltip>
);

const InputText = props => {
  const {
    id,
    label,
    placeholder,
    errorMessage,
    handleChange,
    regex,
    removeChar,
    lengthToBeValidated = 0,
    tooltipMessage = '',
    displayError = null,
    aditionalValidator = null,
  } = props;
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState('');
  const [mustBeValidated, setMustBeValidated] = useState(false);

  useEffect(() => {
    if (displayError !== null) {
      setIsValid(!displayError);
    }
  }, [displayError]);

  const validateInput = textValue => {
    let validValue = true;

    if (textValue.length >= lengthToBeValidated || mustBeValidated) {
      setMustBeValidated(true);

      if (regex && !regex.test(textValue)) {
        validValue = false;
      }
      
      if (aditionalValidator) {
        validValue = aditionalValidator(textValue);
      }
    }

    if (validValue) {
      setIsValid(true);
      handleChange(textValue.trim());
    } else {
      setIsValid(false);
      handleChange('');
    }
  };

  const onChange = event => {
    let textValue = event.target.value;

    if (removeChar) {
      const replace = `regex\\${removeChar}`;
      const re = new RegExp(replace, 'g');
      textValue = textValue.replace(re, '');
    }

    setValue(textValue);
    validateInput(textValue);
  };

  return (
    <div className='inp-text__wrapper'>
      <label className='inp-text__label'>{label}</label>
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={properties => tooltipMessage ? renderTooltip(properties, tooltipMessage) : <span></span>}
      >
        <input
          className={`inp-text__input ${!isValid ? 'inp-text__input--error' : ''}`}
          id={id}
          type='text'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </OverlayTrigger>

      <ErrorMessage show={!isValid} message={errorMessage} />
    </div>
  );
};

export default InputText;
