import React, { useState } from 'react';
import './styles.scss';

const CheckItem = ({ value, label, onChange }) => {
  const [checked, setChecked] = useState(false);

  return (
    <label
      className={`survey-checkbox__label ${checked ? 'survey-checkbox__label--checked' : ''}`}
    >
      <input
        type='checkbox'
        className='survey-checkbox__input'
        value={value}
        onChange={e => {
          setChecked(e.target.checked);
          onChange(e.target.checked);
        }}
      />
      {label}
    </label>
  );
};

export default CheckItem;
