import { numberWithDots } from "./utils";
import { getSavedCountry } from '../properties';

export const removeHourSuffix = str => str.split(" hrs")[0];

export const addZero = time =>  time < 10 ? `0${time}` : `${time}`;

export const setServiceIntervalsFromDuration = (serviceDuration) => {
  const { hours, minutes } = serviceDuration;
  const intervalInMins = hours * 60 + minutes;

  const intervals = [];

  // * 540 represents 09:00 (the first 540 mins of the day)
  // * 1260 represents 21:00 (the fist 1260 mins of the day)
  const storeFromHourInMins = 540;
  const storeToHourInMins = 1260;
  for (
    let i = storeFromHourInMins;
    i < storeToHourInMins;
    i += intervalInMins
  ) {
    // * mins of the day / 60 => left side of hour (decimal part)
    // * mins of the day % 60 => right side of hour
    const fromHour = Math.floor(i / 60);
    const fromMin = i % 60;
    const from = `${addZero(fromHour)}:${addZero(fromMin)}`;

    const toHour = Math.floor((i + intervalInMins) / 60);
    const toMin = (i + intervalInMins) % 60;
    const to = `${addZero(toHour)}:${addZero(toMin)}`;

    intervals.push(`${from} a ${to} hrs`);
  }
  return intervals;
};

export const displayServicePriceWithPrefix = (price, prefix = '') => {
  const country = getSavedCountry();
  
  if (price === 0 || prefix.toUpperCase() === 'GRATIS' || isNaN(parseInt(price))) {
    return 'Gratuito'
  }

  if (prefix) {
    if (country === "es_PE") {
      return `${prefix} S/ ${numberWithDots(price)}`
    }
    
    return `${prefix} $${numberWithDots(price)}`
  }

  return `$${numberWithDots(price)}`
}
