import React, { useState, useEffect, useContext } from 'react';
import Select from '../../inputs/select';
import useCountry from '../../../hooks/countryHook';
import ScheduleButton from '../../buttons/ScheduleButton';
import ServiceContext from '../../../contexts/serviceContext';
import ScheduleModal from '../../../components/modal/ScheduleModal';
import { getStores, getSubLocations, getLocales, getLocations } from '../../../api';
import './style.scss';

export const DeliveryType = {
  Online: 'ONLINE',
  Store: 'TIENDA',
  Local: 'LOCAL',
  Domicile: 'DOMICILIO',
};

const defaultFilters = {
  location: '',
  store: '',
  subLocation: '',
  local: '',
};

const defaultServiceInformation = {
  sku: '',
  serviceName: '',
  locationName: '',
  storeName: '',
  subLocationName: '',
  localName: '',
  placeOfDelivery: '',
};

const ProductDetailPageForm = ({ service, history, serviceFormulary }) => {
  const { t, getLocationName, getSubLocationName, getCountryName } = useCountry();
  const sku = service.sku;
  const type = service.placeOfDelivery?.toUpperCase() || '';
  const [displayInputs, setDisplayInputs] = useState(true);
  const [enabledButton, setEnabledButton] = useState(false);
  const [locationsItems, setLocationsItems] = useState([]);
  const [stores, setStores] = useState([]);
  const [subLocationsItems, setSubLocations] = useState([]);
  const [locals, setLocals] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [serviceInformation, setServiceInformation] = useState(defaultServiceInformation);

  const { setCheckoutInformation } = useContext(ServiceContext);

  useEffect(() => {
    switch (type) {
      case DeliveryType.Store:
        setEnabledButton(!!(locationsItems.length && stores.length && !!filters.store));
        break;
      case DeliveryType.Domicile:
        setEnabledButton(!!(locationsItems.length && subLocationsItems.length && !!filters.subLocation));
        break;
      case DeliveryType.Local:
        setEnabledButton(!!(locationsItems.length && subLocationsItems.length && !!filters.subLocation && !!filters.local));
        break;
      case DeliveryType.Online:
        setDisplayInputs(false);
        setEnabledButton(true);
        break;
      default:
        break;
    }
  }, [type, filters, locationsItems, stores, subLocationsItems]);

  useEffect(() => {
    if (service && Object.keys(service).length) {
      setServiceInformation(old => ({
        ...old,
        sku: service.sku,
        serviceName: service.shortName,
        placeOfDelivery: service.placeOfDelivery,
      }));

      parseLocations(service.sku);
    }
  // eslint-disable-next-line
  }, [service]);

  const parseLocations = async (sku) => {
    const locationName = getLocationName();
    const countryName = getCountryName();
    const data = await getLocations(locationName, sku, countryName);
    setLocationsItems(
      data.map(d => ({
        value: d.idProvince || d.idLocation,
        text: d.province || d.name,
      }))
    );
  };

  const parseStore = data => {
    setStores(
      data.map(d => ({
        value: d.idStore,
        text: d.name,
      }))
    );
  };

  const parseSubLocations = data => {
    setSubLocations(
      data.map(d => ({
        value: d.idLocation,
        text: d.name,
      }))
    );
  };

  const parseLocals = data => {
    setLocals(
      data.map(d => ({
        value: d.idLocal,
        text: d.name,
      }))
    );
  };

  const handleSelectLocation = async locationId => {
    clearLocalStorage();
    setFilters({
      ...defaultFilters,
      location: locationId,
    });

    if (locationId) {
      const locationName = locationsItems.find(p => p.value.toString() === locationId.toString()).text;
      setServiceInformation({
        ...serviceInformation,
        locationName: locationName || '',
      });

      if (type === DeliveryType.Store) {
        const data = await getStores(locationId, sku);

        if (data.respCode === 200) {
          parseStore(data.data);
        }
      } else if (type === DeliveryType.Domicile || type === DeliveryType.Local) {
        const countryName = getCountryName();
        const subLocationName = getSubLocationName();
        const data = await getSubLocations(subLocationName, sku, locationId, countryName);

        if (data && 'respCode' in data && data.respCode === 200) {
          parseSubLocations(data.data);
        } else if (typeof data === 'object') {
          parseSubLocations(data);
        }
      }
    }
  };

  const handleSelectStore = storeId => {
    setFilters(prev => ({
      ...prev,
      store: storeId,
    }));

    if (storeId) {
      const storeName = stores.find(p => parseInt(p.value) === parseInt(storeId)).text;
      setServiceInformation(old => ({
        ...old,
        storeName: storeName || '',
      }));
    }
  };

  const handleSelectSubLocation = async idLocation => {
    setFilters(prev => ({
      ...prev,
      subLocation: idLocation,
      store: '',
      local: '',
    }));

    if (idLocation) {
      const subLocationName = subLocationsItems.find(p => parseInt(p.value) === parseInt(idLocation)).text;


      setServiceInformation(old => ({
        ...old,
        subLocationName: subLocationName || '',
      }));

      if (type === DeliveryType.Local) {
        const data = await getLocales(idLocation, sku);

        if (data && 'respCode' in data && data.respCode === 200) {
          parseLocals(data.data);
        } else if (typeof data === 'object') {
          parseLocals(data);
        }
      }
    }
  };

  const handleSelectLocal = localId => {
    setFilters(prev => ({
      ...prev,
      local: localId,
    }));

    if (localId) {
      const localName = locals.find(p => p.value === localId).text;
      setServiceInformation(old => ({
        ...old,
        localName: localName || '',
      }));
    }
  };

  const clearLocalStorage = () => {
    setServiceInformation(defaultServiceInformation);
  };

  const handleScheduleButton = () => {
    let tmpServiceFilterLabels = {
      subLocation: {
        value: null,
        text: '',
      },
      local: {
        value: null,
        text: '',
      },
      location: {
        value: null,
        text: '',
      },
      store: {
        value: null,
        text: '',
      },
    };

    if (filters.subLocation) {
      const selected = subLocationsItems.find(i => i.value === filters.subLocation);
      if (selected) {
        tmpServiceFilterLabels.subLocation = selected;
      }
    }

    if (filters.local) {
      const selected = locals.find(i => i.value === filters.local);
      if (selected) {
        tmpServiceFilterLabels.local = selected;
      }
    }

    if (filters.location) {
      const selected = locationsItems.find(i => i.value === filters.location);
      if (selected) {
        tmpServiceFilterLabels.location = selected;
      }
    }

    if (filters.store) {
      const selected = stores.find(i => i.value === filters.store);
      if (selected) {
        tmpServiceFilterLabels.store = selected;
      }
    }

    setCheckoutInformation(oldData => ({
      ...oldData,
      ...tmpServiceFilterLabels,
    }));

    setShowCalendar(true);
  };

  return (
    <div>
      <ScheduleModal
        show={showCalendar}
        toggleShow={() => setShowCalendar(false)}
        filters={{
          ...filters,
          sku,
          type,
          restrictSaleSchedule: service.restrictSaleSchedule,
        }}
        service={service}
        history={history}
        serviceInformation={serviceInformation}
        serviceFormulary={serviceFormulary}
      />

      <div className='pdp-form'>
        <div className={!displayInputs ? 'pdp-form--hidden' : ''}>
          <span className='pdp-form__instructions'>{t('plp.form.title')}</span>

          <div className={`pdp-form__item ${!locationsItems.length ? 'pdp-form--hidden' : ''}`}>
            <Select
              label={t('plp.form.province-label')}
              items={locationsItems}
              onSelect={handleSelectLocation}
              placeHolder={t('plp.form.province-placeholder')}
              id='selprovincetitle'
              storageName='provinceTxt'
            />
          </div>

          <div className={`pdp-form__item ${!subLocationsItems.length ? 'pdp-form--hidden' : ''}`}>
            <Select
              label={t('plp.form.department-label')}
              items={subLocationsItems}
              onSelect={handleSelectSubLocation}
              placeHolder={t('plp.form.department-placeholder')}
              id='selcomunatitle'
              storageName='comunaTxt'
            />
          </div>

          <div className={`pdp-form__item ${!stores.length ? 'pdp-form--hidden' : ''}`}>
            <Select
              label={t('plp.form.store-label')}
              items={stores}
              onSelect={handleSelectStore}
              placeHolder={t('plp.form.store-placeholder')}
              id='selstoretitle'
              storageName='storeTxt'
            />
          </div>

          <div className={`pdp-form__item ${!locals.length ? 'pdp-form--hidden' : ''}`}>
            <Select
              label={t('plp.form.local-label')}
              items={locals}
              onSelect={handleSelectLocal}
              placeHolder={t('plp.form.local-placeholder')}
              id='selLocaltitle'
              storageName='LocalTxt'
            />
          </div>
        </div>

        <div className='pdp-form__item pdp-form--center'>
          <ScheduleButton handleClick={handleScheduleButton} enabled={enabledButton} />
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPageForm;
