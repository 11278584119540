import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import LoadBannerTop from '../components/banner/BannerTop';
import LoadFilterStore from '../components/filter/FilterStore';
import { LoadPaginator } from '../components/Paginator';
import Loader from '../components/loader';
import LoadServiceItem from '../components/item/LoadServiceItem';
import Layout from '../components/layout/Layout';
import FilterForm from '../components/filter/FilterForm';
import FilterFormMobile from '../components/filter/FilterForm/FilterFormMobile';
import FilterStoreMobile from '../components/filter/FilterForm/FilterStoreMobile';
import '../styles/Falabella.css';
import '../styles/FalabellaMedia.css';
import api from '../api';
import ServiceContext from '../contexts/serviceContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useCountry from '../hooks/countryHook';

const LoadStructureServices = (props) => {
  const { history, __test__props, __test__change__, __test__store__ } = props;
  const { t } = useCountry();
  const { search } = history.location;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [servicesAll, setServicesAll] = useState(null);
  const [itemsq, setItemsq] = useState([]);
  const [filtroStore, setFiltroStore] = useState({});
  const [servicesTotal] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const { width } = useWindowDimensions();
  const displayMobile = width <= 600;

  const serviceForPages = 16;

  useEffect(() => {
    if (__test__change__) {
      onChangePaginator(__test__change__);
    }
    if (__test__store__) {
      onChangeStore(__test__store__.store, __test__store__.setServiceData, __test__store__.serviceData);
    }
    // eslint-disable-next-line
  }, [__test__change__, __test__store__]);

  // * Reset store filter
  useEffect(() => {
    localStorage.removeItem('profesionalTxt');
    localStorage.removeItem('profesional');
    localStorage.removeItem('locationTxt');
    localStorage.removeItem('location');
    localStorage.removeItem('store');
    localStorage.removeItem('storeTxt');
  }, []);

  useEffect(() => {
    const crypto = window.crypto || window.msCrypto;
    var array = new Uint32Array(1);
    var FileId;
    if (crypto) {
      crypto.getRandomValues(array); // Compliant for security-sensitive use cases
      FileId = array[0];
    }
    if (__test__props) {
      console.warn('Test!');
      let { services: servicesTests } = __test__props;
      const items = [];
      if (servicesTests.length) {
        servicesTests.forEach((service) => {
          items.push(
            <Col
              xs={6}
              md={3}
              className='grid'
              key={`${service.sku}${service.idStore}${FileId}`}
              // key={`${service.sku}${service.idStore}${Math.random()}`}
            >
              <LoadServiceItem service={service} history={history} />
            </Col>
          );
        });
      }

      setServicesAll(items);
      return;
    }
    cargaServicios(filtroStore, 1, serviceForPages, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicesTotal]);

  const cargaServicios = async (store, page, qtyQuery, query) => {
    let obj = {
      location: '',
      store: '',
      page,
      qtyQuery,
      query: '',
    };
    obj.qtyQuery = qtyQuery;
    obj.page = page;
    //EVALUATE PARAMETERS

    if (store.location !== undefined) {
      obj.location = store.location;
    }
    if (query !== undefined && query) {
      obj.query = query;
    }
    if (store.store !== undefined) {
      obj.store = store.store;
    }

    try {
      setLoading(true);
      const res = await api.getServicios(obj);
      setLoading(false);
      const { services, totalPages: pageQuantity } = res;

      const items = [];
      if (services.length) {
        const crypto = window.crypto || window.msCrypto;
        var array = new Uint32Array(1);
        var FileId;
        if (crypto) {
          crypto.getRandomValues(array); // Compliant for security-sensitive use cases
          FileId = array[0];
        }
        services.forEach((service) => {
          items.push(
            <Col
              sm={6}
              xs={6}
              md={3}
              className='grid'
              onClick={() => {
                history.push(`/mas/service-detail/${service.sku}`);
              }}
              // key={`${service.sku}${service.idStore}${Math.random()}`}
              key={`${service.sku}${service.idStore}${FileId}`}
            >
              <LoadServiceItem service={service} history={history} />
            </Col>
          );
        });
      }
      setServicesAll(items);
      setItemsq(res);
      if (pageQuantity != null) setTotalPages(res);
    } catch (error) {
      console.error('cargaServicios -> error', error);
    }
  };

  const onChangeFiltro = (filtro, reset = false, queryParam = '') => {
    const regex = /%20/gi;
    let paramString = history.location.search.replace(regex, ' ');

    if (!queryParam) {
      if (reset) {
        paramString = '';
      } else if (document.getElementById(filtro.id).checked) {
        paramString = `${paramString}&${filtro.title}=${filtro.id}`;
      } else {
        let stringFilter = `&${filtro.title}=${filtro.id}`;
        paramString = paramString.replace(stringFilter, '');
      }
    } else {
      paramString += `${paramString.length ? '&' : ''}${queryParam}`;
    }

    history.push({
      pathname: location ? location.pathname : '',
      search: paramString,
    });

    cargaServicios(filtroStore, 1, serviceForPages, history.location.search);
  };

  const onChangeStore = (store, setServiceData, serviceData) => {
    setFiltroStore(store);
    setServiceData({ ...serviceData, store });
    history.push({
      pathname: location ? location.pathname : '',
      search: null,
    });
    cargaServicios(store, 1, serviceForPages, history.location.search);
  };

  const onChangePaginator = (page) => {
    cargaServicios(filtroStore, page, serviceForPages, history.location.search);
  };

  return (
    <ServiceContext.Consumer>
      {({ setServiceData, serviceData }) => (
        <Layout>
          <Container>
            <Loader loading={loading} />
            <Row className='justify-content-md-center row-fluid ResponsiveHeader'>
              <Col className='col-sm-12 banner-container'>
                <LoadBannerTop isPlp />
              </Col>
            </Row>

            {displayMobile && (
              <Row className='row-fluid'>
                <Col xs='6' style={{ padding: 0 }}>
                  <FilterFormMobile filters={itemsq?.filters || []} handleChange={onChangeFiltro} />
                </Col>
                <Col xs='6' style={{ padding: 0 }}>
                  <FilterStoreMobile handleChange={onChangeFiltro} />
                </Col>
              </Row>
            )}

            <Row className='row-fluid'>
              {!displayMobile && (
                <Col xs='12' md='3' className='filter-bar'>
                  {itemsq.length !== 0 && (
                    <FilterForm filters1={itemsq} onChangeFiltro={onChangeFiltro} history={history} />
                  )}
                </Col>
              )}

              <Col xs='12' md='9' id='services-display'>
                {!displayMobile && (
                  <Row className='paginator-bar-top'>
                    <Col className='cc md-justify-start' xs='12' md='9'>
                      <LoadFilterStore
                        inline={true}
                        onChangeStore={(store) => {
                          onChangeStore(store, setServiceData, serviceData);
                        }}
                      />
                    </Col>

                    <Col className='cc md-justify-end' xs='12' md='3'>
                      <div id='paginator-top-pages'>{`${t('pdp.pages')}: `}</div>

                      <LoadPaginator data={itemsq} onChange={onChangePaginator} total={totalPages} />
                    </Col>
                  </Row>
                )}

                <Row className='result-bar flex-1' style={{ display: 'block' }}>
                  {servicesAll}
                </Row>
                <Row className='paginator-bar cc'>
                  {/* Se comenta indicador de resultados por bug que no muestra resultado correcto después de 3 páginas */}
                  {
                    <div id='paginator-bottom-pages'>
                      {`${itemsq.fromRecord || 0} - ${itemsq.toRecord || 0} de
                      ${totalPages.totalRecord || 0} ${t('pdp.results')}`}
                    </div>
                  }
                  <LoadPaginator data={itemsq} onChange={onChangePaginator} total={totalPages} />
                </Row>
              </Col>
            </Row>
          </Container>
        </Layout>
      )}
    </ServiceContext.Consumer>
  );
};
export default LoadStructureServices;
