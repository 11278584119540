import React, { useState, useEffect } from 'react';
import Menu, { MenuTypes } from '../../menu';
import filterSvg from '../../../img/filter.svg';
import useCountry from '../../../hooks/countryHook';

import './style.scss';

const FilterFormMobile = ({ filters, handleChange }) => {
  const { t } = useCountry();
  const [displayMenu, setDisplayMenu] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const createItems = () => {
      const items = filters.map((filter) => ({
        type: MenuTypes.DROP,
        text: filter.name,
        values: filter.values.map((option) => ({
          ...option,
          type: MenuTypes.SELECTABLE,
        })),
      }));
  
      setMenuItems(items);
    };

    if (filters.length) {
      createItems();
    }
  }, [filters]);

  const applyFilters = (itemList) => {
    if (itemList.length) {
      let queryParam = '';

      itemList.forEach((item) =>
        item.values.forEach((check) => {
          if (check.selected) {
            queryParam += `${item.text}=${check.id}&`;
          }
        })
      );

      if (queryParam.length) {
        queryParam = queryParam.substr(0, queryParam.length - 1);
        handleChange('', false, queryParam);
      } else {
        handleChange('', true);
      }
    } else {
      handleChange('', true);
    }
  };

  return (
    <>
      <div className='filter-form' onClick={() => setDisplayMenu(true)}>
        <span className='filter-form__icon'>
          <img src={filterSvg} alt={t('pdp.filters.filtersText')} />
        </span>
        <span className='filter-form__text'>{t('pdp.filters.filtersText')}</span>
      </div>
      <Menu
        display={displayMenu}
        title={t('pdp.filters.filterForText')}
        handleClose={() => setDisplayMenu(false)}
        items={menuItems}
        handleChange={applyFilters}
      />
    </>
  );
};

export default FilterFormMobile;
