import React from 'react';
import useCountry from 'hooks/countryHook';
import './styles.scss';

interface iService {
  imgServiceRecommendationUrl: string;
  shortName: string;
  recommendedSku: string;
}

interface iProps {
  services?: iService[];
}

const RecommendedServices = ({ services = [] }: iProps) => {
  const { t } = useCountry();
  return (
    <div className='recommended-services'>
      <h3 className='recommended-services__title'>{t('recommended-services.title')}</h3>

      <div className='recommended-services__list'>
        {services.map((service, index) => (
          <a key={index} className='recommended-services__item' href={`/mas/service-detail/${service.recommendedSku}`}>
            <img src={service.imgServiceRecommendationUrl} alt={service.shortName} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default RecommendedServices;
