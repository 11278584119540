import React from 'react';
import useCountry from 'hooks/countryHook';
import CheckSvg from '../../img/green_check.svg';

const SuccessWithoutForm = () => {
  const { t } = useCountry();
  return (
    <div className='checkout-header'>
      <h2 className='checkout-header__title'>{t('checkout.success-without-form.title')}</h2>
      <div className='checkout-header__subtitle'>{t('checkout.success-without-form.sub-title')}</div>
      <div className='checkout-header__icon'>
        <img src={CheckSvg} alt='Check' />
      </div>
    </div>
  );
};

export default SuccessWithoutForm;
