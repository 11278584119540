import React from 'react';
import './styles.scss';

const TinyCheckItem = ({ value, label, checked, onChange }) => {
  return (
    <label
      className={`survey-tiny-checkbox__label ${checked ? 'survey-tiny-checkbox__label--checked' : ''}`}
    >
      <input
        type='checkbox'
        className='survey-tiny-checkbox__input'
        value={value}
        onChange={() => {}}
        onClick={() => onChange(!checked)}
      />
      {label}
    </label>
  );
};

export default TinyCheckItem;
