import React from 'react';
import NavBar from '../navbar';
import Footer from '../footer/Footer';
import './styles.scss';

const Layout = ({ children }) => {
  return (
    <>
      <NavBar />
      <div className='mas-wrapper'>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
