import React from 'react';
import useCountry from 'hooks/countryHook';
import './style.scss';

interface iProps {
  enabled: boolean;
  handleClick: () => void;
}

const ScheduleButton = ({ enabled, handleClick }: iProps) => {
  const { t } = useCountry();

  return (
    <button
      type='button'
      id='btnReservar'
      onClick={handleClick}
      disabled={!enabled}
      className={`schedule-button ${!enabled ? `schedule-button--disabled` : ''}`}
    >
      {t('plp.form.schedule-button')}
    </button>
  );
};

export default ScheduleButton;
