import React from "react";
import useCountry from '../hooks/countryHook';

export function LoadPaginator({
  data,
  onChange,
  total,
}) {
  const { t } = useCountry();
  const {currentPage } = data;
  const {totalPages}=total;
      const items = [];
      const itemArrowLeft = [];
      const itemArrow = [];
      let showArrowLeft = true;
      let prevPage = currentPage - 1;
      let nextPage = currentPage + 1;
      for (let x = 1; x <= totalPages; x++) {
        //PAGE SELECTED
        if (currentPage === x) {
          items.push(
            <li key={`${x}_current`} className="selected">
              {x}
            </li>
          );
          prevPage = x - 1;
          nextPage = x + 1;
        } else if (x === nextPage || x === prevPage || x === 1 || x === 3) {
          //PAGE UNSELECTED
          items.push(
            <li key={x} className="pagination-item">
              <button
                onClick={(e) => {
                  onChange(x);
                }}
                className="pagination-button"
              >
                {x}
              </button>
            </li>
          );
        }
        //SHOW ELLIPSES
        if (
          (x === prevPage - 1 && prevPage > 2) ||
          (x === nextPage + 1 && nextPage < 3 - 1)
        ) {
          items.push(
            <li key={`${x}_ellipsis`} className="ellipses">
              ...
            </li>
          );
        }
        //SHOW LEFT ARROW
        if (currentPage > 1 && showArrowLeft) {
          showArrowLeft = false;
          itemArrowLeft.push(
            <div
              key={`arrowLeft`}
              className="arrow right"
              id="paginator-arrowLeft"
            >
              <button
                type="button"
                onClick={(e) => {
                  onChange(x);
                }}
              >
                <div className="arrow-left-margin">
                  <img
                    alt={t('pdp.paginator.last')}
                    src={"../img/iconarrowrightPagination.svg"}
                    style={{ transform: "rotate(180deg)" }}
                  ></img>
                </div>
              </button>
            </div>
          );
        } else if (currentPage <= 1) {
          itemArrowLeft.push("");
        }
        //SHOW RIGHT ARROW
        if (x === totalPages && totalPages > 1 && currentPage !== totalPages) {
          itemArrow.push(
            <div key={`arrowRight${x}`} className="arrow right">
              <button
                type="button"
                onClick={(e) => {
                  onChange(x);
                }}
                id="paginator-arrowRight"
              >
                <div className="arrow-left-margin">
                  <img
                    alt={t('pdp.paginator.next')}
                    src={"../img/iconarrowrightPagination.svg"}
                  ></img>
                </div>
              </button>
            </div>
          );
        }
      }

  return (
    <div className="cc">
      <div className="pagination">
        {itemArrowLeft}
        <ol
          className=""
          style={{ margin: 0, padding: 0, display: "flex" }}
          id="paginator-numbersPage"
        >
          {items}
        </ol>
        {itemArrow}
      </div>
    </div>
  );
}
