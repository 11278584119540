import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import Select from '../../inputs/select';
import Blocks from '../../newcalendar/Blocks';
import useCountry from '../../../hooks/countryHook';
import CalendarMobile from '../../newcalendar/CalendarMobile';
import ServiceContext from '../../../contexts/serviceContext';
import api, { getProfessionalsByService, rescheduleAppointment } from '../../../api';
import './style.scss';

const defErrors = {
  professional: false,
  calendar: false,
  blocks: false,
};

const ScheduleModal = (props) => {
  const { t } = useCountry();
  const { show, toggleShow, history, filters, service, isReschedule = false, serviceFormulary, appointmentId = '', token = '' } = props;
  const { sku, type, store, subLocation, local, professional } = filters;
  const { checkoutInformation, setCheckoutInformation } = useContext(ServiceContext);
  const isMobile = window.innerWidth <= 767;
  const [isLoading, setIsLoading] = useState(true);
  const [needCheckAProfessional, setNeedCheckAProfessional] = useState(true);
  const [professionals, setProfessionals] = useState([]);
  const [selectedProfessional, setSelectedProfessional] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState({});
  const [availabilityInfo, setAvailabilityInfo] = useState({});
  const [serviceDailyDisplayHours, setServiceDailyDisplayHours] = useState(null);
  const [formErrors, setFormErrors] = useState(defErrors);

  useEffect(() => {
    const getProfessionals = async () => {
      const data = await getProfessionalsByService(sku, type, store, subLocation, local);

      if (data && 'respCode' in data && data.respCode === 200 && data.data.length) {
        setNeedCheckAProfessional(true);
        parseProfessionalList(data.data);
      } else {
        setNeedCheckAProfessional(false);
      }
      setIsLoading(false);
    };

    if (show) {
      getProfessionals();
    }
  }, [show, sku, type, store, subLocation, local]);

  const parseProfessionalList = (values) => {
    setProfessionals(
      values.map((v) => ({
        value: v.userId,
        text: `${v.professionalName} ${v.professionalLastName}`,
      }))
    );
  };

  const handleClose = () => {
    setSelectedProfessional('');
    setSelectedDate(null);
    setAvailabilityInfo({});
    setFormErrors(defErrors);
    toggleShow();
  };

  const checkForm = () => {
    let validForm = true;
    const tmpErrors = {
      ...defErrors,
    };

    if (needCheckAProfessional && !selectedProfessional) {
      validForm = false;
      tmpErrors.professional = true;
    }

    if (validForm && !selectedDate) {
      validForm = false;
      tmpErrors.calendar = true;
    }

    if (validForm && !selectedBlock.from) {
      validForm = false;
      tmpErrors.blocks = true;
    }

    setFormErrors(tmpErrors);
    return validForm;
  };

  const reschedule = (selectedHour) => {
    let rescheduleObj = {
      appointmentId: appointmentId,
      datetime: selectedHour.datetime,
      from: selectedHour.from,
      to: selectedHour.to,
    };

    setIsLoading(true);
    rescheduleAppointment(token, [rescheduleObj])
      .then((data) => {
        if (data.respCode !== 200) {
          handleRescheduleError();
        }

        if (props.rescheduleSuccess) {
          const professionalName = professionals.find((p) => p.value === selectedProfessional)?.text || '';

          props.rescheduleSuccess({
            date: selectedDate,
            time: selectedBlock,
            professional: professionalName,
          });
        }
      })
      .catch(() => handleRescheduleError())
      .finally(() => setIsLoading(false));
  };

  const createSchedule = async () => {
    const isValid = checkForm();

    if (!isValid) {
      return;
    }
    const timeUnit = availabilityInfo.timeUnit;
    const selectedHour = {
      date: new Date(selectedBlock.from),
      datetime: timeUnit === 'H' ? selectedBlock.from.slice(0, 10).replace(/-/g, '') : null,
      from: timeUnit === 'H' ? selectedBlock.from.slice(11, 16) : selectedBlock.from.slice(0, 10),
      to: timeUnit === 'H' ? selectedBlock.to.slice(11, 16) : selectedBlock.to.slice(0, 10),
      interval: timeUnit === 'H' ? `${selectedBlock.from.slice(11, 16)} a ${selectedBlock.to.slice(11, 16)} hrs` : '',
    };

    let professional = {
      value: null,
      text: '',
    };

    if (selectedProfessional) {
      const selected = professionals.find((p) => p.value === selectedProfessional);
      if (selected) {
        professional = selected;
      }
    }

    if (isReschedule) {
      reschedule(selectedHour);
      return;
    }

    const savedObject = {
      ...checkoutInformation,
      date: selectedBlock.from,
      blockText: selectedBlock.text,
      time: selectedHour.interval,
      professional: professional,

      timeUnit: timeUnit,
      datetime: timeUnit === 'H' ? selectedBlock.from.slice(0, 10).replace(/-/g, '') : null,
      from: timeUnit === 'H' ? selectedBlock.from.slice(11, 16) : selectedBlock.from.slice(0, 10),
      to: timeUnit === 'H' ? selectedBlock.to.slice(11, 16) : selectedBlock.to.slice(0, 10),
      serviceType: type,
    };

    let isComplementary = false;
    let appointmentId = '';

    if (!savedObject.isMayday) {
      try {
        const request = [
          {
            service: {
              sku: savedObject.sku,
            },
            store: {
              idStore: savedObject.placeOfDelivery.toUpperCase() === 'TIENDA' ? savedObject.store.value.toString() : null,
            },
            local: {
              idLocal: savedObject.placeOfDelivery.toUpperCase() === 'LOCAL' ? savedObject.local.value : null,
            },
            professional: {
              idUser: savedObject.professional.value,
            },
            location: {
              idLocation: savedObject.placeOfDelivery.toUpperCase() === 'DOMICILIO' ? savedObject.subLocation.value : null,
            },
            createdBy: {
              idUser: null,
            },
            timeUnit: savedObject.timeUnit,
            datetime: savedObject.datetime,
            from: savedObject.from,
            to: savedObject.to,
          },
        ];

        const response = await api.generateAppointment(request);
        if (!response.data) {
          throw new Error(response.glosa || t('plp.errors.create-schedule-default'));
        }

        isComplementary = response.data[0]?.service?.skuType === 'X';

        const { appointmentId: newAppointmentId } = response.data[0];
        appointmentId = newAppointmentId;
      } catch (error) {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message || t('plp.errors.create-schedule-text'),
          confirmButtonText: t('plp.errors.create-schedule-button'),
          allowOutsideClick: false,
          showConfirmButton: true,
          width: '750px',
          imageWidth: '80px',
          confirmButtonColor: '#aad500',
          customClass: {
            confirmButton: 'confirm-button-class error-button-modal',
            popup: 'popup-class',
          },
        });

        return;
      }
    }

    setCheckoutInformation((oldData) => ({
      ...oldData,
      date: selectedBlock.from,
      blockText: selectedBlock.text,
      time: selectedHour.interval,
      professional: professional,

      timeUnit: timeUnit,
      datetime: timeUnit === 'H' ? selectedBlock.from.slice(0, 10).replace(/-/g, '') : null,
      from: timeUnit === 'H' ? selectedBlock.from.slice(11, 16) : selectedBlock.from.slice(0, 10),
      to: timeUnit === 'H' ? selectedBlock.to.slice(11, 16) : selectedBlock.to.slice(0, 10),
      serviceType: type,

      IsComplementary: isComplementary,
      appointmentId: appointmentId,
    }));

    // Personal shopper survey
    let pathname = '/mas/contact-information';

    if (serviceFormulary) {
      pathname = '/survey';
    }
    
    history.push({ pathname: pathname });
  };

  const handleRescheduleError = () => {
    if (props.setRescheduleError) {
      props.setRescheduleError('error');
      handleClose();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className='service-calendar-modal service-calendar-modal-mobile'>
      <div className='service-calendar-container'>
        <div className='service-calendar-top'>
          <span>{isMobile ? t('plp.schedule-modal.mobile-title') : t('plp.schedule-modal.desktop-title')}</span>
          <button id='service-calendar-modal-btn-close' className='font-lger' onClick={() => handleClose()}>
            <img src='/img/close.svg' alt='X' />
          </button>
        </div>

        <div className='modal-container'>
          <div
            className={`modal-step modal-step__professional ${isMobile ? 'modal-step--bordered' : ''} ${
              !needCheckAProfessional || isLoading ? 'modal-step--hide' : ''
            }`}
          >
            <div className='modal-step__content'>
              <Select
                label={t('plp.schedule-modal.professional-label')}
                items={professionals}
                placeHolder={t('plp.schedule-modal.professional-placeholder')}
                onSelect={setSelectedProfessional}
                id='selprofesionaltitle'
                errorMessage={t('plp.schedule-modal.professional-error')}
                displayError={formErrors.professional}
                defaultSelected={professional}
              />
            </div>
          </div>

          <div className='modal-fixed-steps'>
            <div className={`modal-step modal-step__calendar ${isMobile ? 'modal-step--bordered' : ''}`}>
              <span
                className={`modal-step__title ${
                  needCheckAProfessional && Object.keys(selectedProfessional).length === 0
                    ? 'modal-step__title--disabled'
                    : ''
                }`}
              >
                {isMobile ? t('plp.schedule-modal.mobile-date-title') : t('plp.schedule-modal.desktop-date-title')}
              </span>

              <div className={`modal-step__content ${isMobile ? 'modal-step__content--fw' : ''}`}>
                <CalendarMobile
                  filters={filters}
                  professional={selectedProfessional}
                  history={history}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  setAvailabilityInfo={setAvailabilityInfo}
                  setServiceDailyDisplayHours={setServiceDailyDisplayHours}
                  maydayIntegrationIsActive={service?.maydayIntegrationIsActive || false}
                  readyToSearch={!needCheckAProfessional || !!selectedProfessional}
                  displayError={formErrors.calendar}
                  errorMessage={isMobile ? t('plp.schedule-modal.mobile-date-error') : t('plp.schedule-modal.desktop-date-error')}
                />
              </div>
            </div>

            <div className='modal-step modal-step__blocks modal-step--without-border' id='modal-step__blocks'>
              <span
                className={`modal-step__title modal-step__title--mobile ${
                  Object.keys(availabilityInfo).length === 0 || availabilityInfo?.availabilityBlocks.length === 0
                    ? 'modal-step__title--disabled'
                    : ''
                }`}
              >
                {t('plp.schedule-modal.block-title')}
              </span>

              <div className='modal-step__content modal-step__content--mh-410'>
                <Blocks
                  selectedDate={selectedDate}
                  availabilityInfo={availabilityInfo}
                  setSelectedBlock={setSelectedBlock}
                  displayError={formErrors.blocks}
                  dailyDisplayHours={serviceDailyDisplayHours}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`service-calendar-footer cc ${isMobile ? 'service-calendar-footer--bordered' : ''}`}>
          <button
            className={`schedule-modal-button ${!isMobile ? 'desktop-button' : ''}`}
            onClick={createSchedule}
            id='agendar-button'
          >
            {t('plp.schedule-modal.schedule-button-text')}
          </button>
        </div>
      </div>

      {<style>{`body { overflow: hidden; }`}</style>}
    </div>
  );
};

export default ScheduleModal;
