import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import useCountry from '../../../hooks/countryHook';
import ServiceContext from '../../../contexts/serviceContext';
import { displayServicePriceWithPrefix } from '../../../utils/service';
import './styles.scss';

const defaultData = {
  imageUrl: '',
  brand: '',
  name: '',
  description: '',
  date: '',
  price: '',
  storeName: '',
  localName: '',
  locationName: '',
  subLocationName: '',
  professionalName: '',
  time: '',
  duration: '',
};

const ServiceSummary = ({ isComplementary = false }) => {
  const { t } = useCountry();
  const { checkoutInformation } = useContext(ServiceContext);
  const [summaryData, setSummaryData] = useState(defaultData);
  const [specialService, setSpecialService] = useState(true);

  const parsedDuration = duration => {
    if (!duration || !Object.keys(duration).length) {
      return '';
    }

    let durationText = '';

    if (duration.weeks === 1) {
      durationText += `${duration.weeks} ${t('contact-information.summary.week')} `;
    } else if (duration.weeks !== 0) {
      durationText += `${duration.weeks} ${t('contact-information.summary.weeks')} `;
    }

    if (duration.days === 1) {
      durationText += `${duration.days} ${t('contact-information.summary.day')} `;
    } else if (duration.days !== 0) {
      durationText += `${duration.days} ${t('contact-information.summary.days')} `;
    }

    if (duration.hours === 1) {
      durationText += `${duration.hours} ${t('contact-information.summary.hour')} `;
    } else if (duration.hours !== 0) {
      durationText += `${duration.hours} ${t('contact-information.summary.hours')} `;
    }

    if (duration.minutes === 1) {
      durationText += `${duration.minutes} ${t('contact-information.summary.week')} `;
    } else if (duration.minutes !== 0) {
      durationText += `${duration.minutes} ${t('contact-information.summary.weeks')} `;
    }

    return durationText;
  };

  useEffect(() => {
    if (checkoutInformation && Object.keys(checkoutInformation).length) {
      setSpecialService(checkoutInformation.sku.toUpperCase() === 'PROD2786809');

      let professionalName = checkoutInformation.professional.text || '';
      if (professionalName.length >= 26) {
        professionalName = professionalName.slice(0, 23) + '...';
      }

      setSummaryData({
        ...defaultData,
        locationName: checkoutInformation.location.text || '',
        subLocationName: checkoutInformation.subLocation.text || '',
        localName: checkoutInformation.local.text || '',
        professionalName: professionalName,
        storeName: checkoutInformation.store.text || '',

        imageUrl: checkoutInformation.imageUrl || '',
        brand: checkoutInformation.brand || '',
        name: checkoutInformation.name || '',
        description: checkoutInformation.description || '',
        price: displayServicePriceWithPrefix(
          checkoutInformation.price || 0,
          checkoutInformation.pricePrefix?.prefix || ''
        ),

        date: checkoutInformation.date ? moment(checkoutInformation.date).format(t('contact-information.summary.date-format')) : '',
        time: checkoutInformation.time || '',
        duration: parsedDuration(checkoutInformation.duration),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutInformation]);

  return (
    <div className={`service-summary__wrapper ${specialService ? 'service-summary__wrapper--special' : ''}`}>
      <img src={summaryData.imageUrl} alt={summaryData.name} />

      <div className='service-summary__content'>
        <span className='service-summary__brand-text'>{summaryData.brand}</span>

        <h3 className='service-summary__name'>{summaryData.name}</h3>

        <div className='service-summary__list'>
          <h4>{t('contact-information.summary.title')}</h4>
          <ul>
            <li className={`service-summary__list-item ${!summaryData.storeName ? 'hide' : ''}`}>
              <label>{t('contact-information.summary.place')}:</label>
              <span>{summaryData.storeName}</span>
            </li>

            <li className={`service-summary__list-item ${!summaryData.localName ? 'hide' : ''}`}>
              <label>{t('contact-information.summary.local')}:</label>
              <span>{summaryData.localName}</span>
            </li>

            <li className={`service-summary__list-item ${(!summaryData.locationName || !isComplementary) ? 'hide' : ''}`}>
              <label>{t('contact-information.summary.province')}:</label>
              <span>{summaryData.locationName}</span>
            </li>

            <li className={`service-summary__list-item ${!summaryData.subLocationName ? 'hide' : ''}`}>
              <label>{t('contact-information.summary.district')}:</label>
              <span>{summaryData.subLocationName}</span>
            </li>

            <li className={`service-summary__list-item ${!summaryData.professionalName ? 'hide' : ''}`}>
              <label>{t('contact-information.summary.professional')}:</label>
              <span>{summaryData.professionalName}</span>
            </li>

            <li className='service-summary__list-item'>
              <label>{t('contact-information.summary.schedule')}:</label>
              <span>{summaryData.date}</span>
            </li>

            <li className={`service-summary__list-item ${!summaryData.time ? 'hide' : ''}`}>
              <label>{t('contact-information.summary.time')}:</label>
              <span>{summaryData.time}</span>
            </li>

            <li className='service-summary__list-item'>
              <label>{t('contact-information.summary.price')}:</label>
              <span>{summaryData.price}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceSummary;
