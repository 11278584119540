import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../components/loader';
import useCountry from '../../hooks/countryHook';
import Layout from '../../components/layout/Layout';
import ServiceContext from '../../contexts/serviceContext';
import LoadBannerTop from '../../components/banner/BannerTop';
import ServiceSummary from '../../components/service/summary';
import CheckoutForm from '../../components/service/checkoutForm';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Breadcrumb from '../../components/breadcrumbs/pdpBreadcrumb';
import FormTitle from '../../components/service/checkoutForm/title';
import './styles.scss';

const ContactInformation = ({ history }) => {
  const { t } = useCountry();
  const { width } = useWindowDimensions();
  const displayBanner = width >= 800;
  const isMobile = width <= 580;

  const [loading, setLoading] = useState(true);
  const [appointmentId, setAppointmentId] = useState('');
  const [isComplementary, setIsComplementary] = useState(false);
  const { checkoutInformation } = useContext(ServiceContext);

  useEffect(() => {
    if (checkoutInformation && Object.keys(checkoutInformation).length) {
      if (!checkoutInformation.isMayday) {
        setIsComplementary(checkoutInformation.isComplementary);
        setAppointmentId(checkoutInformation.appointmentId);
      }
    } else {
      history.push('/mas');
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutInformation]);

  return (
    <Layout>
      <Loader loading={loading} />
      <main className='cont-inf__wrapper'>
        <LoadBannerTop bannerProps={checkoutInformation.serviceBanner} display={displayBanner} />

        <section>
          <nav>
            <Breadcrumb shortName={checkoutInformation.name || ''} sku={checkoutInformation.sku || ''} isCheckout />

            <div className='cont-inf__content'>
              <FormTitle display={isMobile} isComplementary={isComplementary} />

              <div className='cont-inf__details'>
                <ServiceSummary isComplementary={isComplementary} sku={checkoutInformation.sku || ''} />

                <div className='cont-inf__details-separator'></div>

                <div>
                  <FormTitle display={!isMobile} isComplementary={isComplementary} />
                  <CheckoutForm appointmentId={appointmentId} />
                </div>
              </div>

              <div className='cont-inf__footer'>
                {t('help.message').trim() && (
                  <>
                    <img src='/img/contact_person.svg' alt='' />
                    {t('help.message')} <b>{t('help.number')}</b>
                  </>
                )}
              </div>
            </div>
          </nav>
        </section>
      </main>
    </Layout>
  );
};

export default ContactInformation;
