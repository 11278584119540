import axios from 'axios';
import { properties, getCountryHeader } from '../properties';

export const defaultHeader = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-commerce': 'falabella',
    'X-country': getCountryHeader(),
    'X-cmRef': 'MAS-Frontend-client',
    'X-chRef': 'Web',
    'X-prRef': 'Omnichannel',
  };
};

const getcategories = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/categories`,
      headers: defaultHeader(),
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const getbrands = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/brands`,
      headers: defaultHeader(),
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const getServicios = async (obj) => {
  let query = obj.query || '';
  try {
    const res = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/services${query}`,
      params: {
        locationId: obj.location,
        storeId: obj.store,
        qtyQuery: obj.qtyQuery,
        page: obj.page,
      },
      headers: defaultHeader(),
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const generateAppointment = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${properties.backendUrl}/client/appointments`,
      headers: defaultHeader(),
      data,
    });

    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

export const getStores = async (locationId, sku) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/stores`,
      params: {
        locationId: locationId,
        sku: sku,
      },
      headers: defaultHeader(),
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const confirmAppointment = async (data) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `${properties.backendUrl}/client/appointments/confirm/${data.client.appointmentId}`,
      headers: defaultHeader(),
      data,
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const confirmMaydayAppointment = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${properties.backendUrl}/client/appointments/confirm/mayday`,
      headers: defaultHeader(),
      data,
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const saveSurvey = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${properties.backendUrl}/client/survey`,
      headers: defaultHeader(),
      data,
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const getProfessionals = async () => {
  try {
    const url = `${properties.backendUrl}/client/professionals`;
    const res = await axios({
      method: 'GET',
      headers: defaultHeader(),
      url,
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

export const getRegionIn = async (sku) => {
  try {
    const url = `${properties.backendUrl}/client/parent/locations?sku=${sku || ''}`;
    const res = await axios({
      method: 'GET',
      headers: defaultHeader(),
      url,
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

export const getTiendaIn = async (locationId, sku) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/stores?locationId=${locationId}&sku=${sku || ''}`,
      headers: defaultHeader(),
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

export const getProfessionalsByService = async (sku, deliveryType, storeId, subLocationId, localId) => {
  try {
    let queryParams = `sku=${sku}`;
    const type = deliveryType.toUpperCase();

    if (type === 'TIENDA') {
      queryParams += `&storeId=${storeId}`;
    } else if (type === 'DOMICILIO') {
      queryParams += `&locationId=${subLocationId}`;
    } else if (type === 'LOCAL') {
      queryParams += `&localId=${localId}`;
    }

    const res = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/professionals?${queryParams}`,
      headers: defaultHeader(),
    });

    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

export const getLocations = async (locationName, sku, countryName) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/parent/types`,
      params: {
        sku: sku || null,
        locationType: locationName,
        countryName: countryName.toUpperCase(),
      },
      headers: defaultHeader(),
    });
    return res.data.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

export const getSubLocations = async (locationType, sku, locationId, countryName) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/parent/types`,
      params: {
        idParent: locationId || '',
        locationType: locationType,
        countryName: countryName.toUpperCase(),
        sku,
      },
      headers: defaultHeader(),
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

export const getLocales = async (idLocation, sku) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/locals?idLocation=${idLocation}&sku=${sku}`,
      headers: defaultHeader(),
    });

    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const getTerms = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/tc?latest=true`,
      headers: defaultHeader(),
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

export const getServiceDetails = async (sku) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/service/${sku}`,
      headers: defaultHeader(),
    });

    if (response && response.data && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    return error?.response?.data || '';
  }

  return null;
};

const getServiceAvailability = async (params, seeLog = false) => {
  const { tipoEntrega, sku } = params;
  const serviceIsNotOnline = tipoEntrega.toUpperCase() !== 'ONLINE';

  try {
    const response = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/service/availability`,
      params: serviceIsNotOnline ? { ...params } : { sku },
      headers: defaultHeader(),
    });

    if (!response.data || !response.data.data) {
      return null;
    }

    const { availabilityBlocks } = response.data.data[0];

    if (!availabilityBlocks) {
      return null;
    }

    // * Arrange availability by date
    // const hoursByDates = {};
    // availabilityBlocks.forEach((stockBlock) => {
    //   const { from } = stockBlock;

    //   const date = from.split(" ")[0];

    //   hoursByDates[date] = hoursByDates[date]
    //     ? [...hoursByDates[date], stockBlock]
    //     : [stockBlock];
    // });

    return response.data.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

export const getServiceAvailabilityByDate = async (parameters, tipoEntrega) => {
  const { availabilitiesFromDate, availabilitiesToDate, productSKU, storeId, professionalId, localId, locationId } =
    parameters;

  try {
    const queryParams = {
      sku: productSKU,
      availabilitiesFromDate,
      availabilitiesToDate,
    };

    switch (tipoEntrega.toUpperCase()) {
      case 'TIENDA':
        queryParams.storeId = storeId;
        break;
      case 'DOMICILIO':
        queryParams.locationId = locationId || '';
        break;
      case 'LOCAL':
        queryParams.localId = localId;
        break;
      default:
        break;
    }

    if (professionalId) {
      queryParams.professionalId = professionalId;
    }

    const response = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/service/availability`,
      params: queryParams,
      headers: defaultHeader(),
    });

    if (response.status === 200 && response.data.respCode === 200) {
      return response.data.data[0];
    }

    throw new Error(response?.data?.glosa || 'Error obteniendo datos');
  } catch (error) {
    return error?.response?.data || '';
  }
};

const getServiceAvailability2 = async (params) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${properties.backendUrl}/client/service/availability`,
      params: { ...params },
      headers: defaultHeader(),
    });

    if (!response.data || !response.data.data) {
      return null;
    }

    const { availabilityBlocks } = response.data.data[0];

    if (!availabilityBlocks) {
      return null;
    }

    return response.data.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const confirmEmailAppointment = async (token) => {
  try {
    const response = await axios({
      method: 'PATCH',
      url: `${properties.backendUrl}/client/appointments/confirm/email`,
      params: {
        token: token,
      },
      headers: defaultHeader(),
    });

    if (!response.data) {
      return null;
    }

    return response.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const cancelEmailAppointment = async (token) => {
  try {
    const response = await axios({
      method: 'PATCH',
      url: `${properties.backendUrl}/client/appointments/cancel/email`,
      params: {
        token: token,
      },
      headers: defaultHeader(),
    });

    if (!response.data) {
      return null;
    }

    return response.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

export const rescheduleAppointment = async (token, body) => {
  try {
    const response = await axios.patch(
      `${properties.backendUrl}/client/appointments/reschedule/email?token=${token}`,
      body,
      {
        headers: defaultHeader(),
      }
    );

    if (!response.data) {
      return null;
    }

    return response.data;
  } catch (error) {
    return error?.response?.data || '';
  }
};

const Api = {
  getcategories,
  getbrands,
  getServicios,
  getTiendaIn,
  getRegionIn,
  getStores,
  getLocations,
  getSubLocations,
  getLocales,
  generateAppointment,
  confirmAppointment,
  getProfessionals,
  getTerms,
  getServiceDetails,
  getServiceAvailability,
  getServiceAvailabilityByDate,
  getServiceAvailability2,
  confirmEmailAppointment,
  cancelEmailAppointment,
  confirmMaydayAppointment,
  rescheduleAppointment,
  saveSurvey,
};

export default Api;
