import React from 'react';
import useCountry from 'hooks/countryHook';
import './styles.scss';

const Footer = () => {
  const { t } = useCountry();

  return (
    <footer className='footer'>
      <span>{`${t('footer.falabella')} | © ${t('footer.copyright')}`}</span>
    </footer>
  );
};

export default Footer;
