import React from 'react';
import './styles.scss';

const RadioItem = ({ value, name, onChange, label, tiny, checked }) => {
  return (
    <label
      className={
        'survey-radio__label '
        + (tiny ? 'survey-radio__label--tiny ' : '')
        + (checked ? 'survey-radio__label--checked ' : '')
      }
    >
      <input className='survey-radio__input' type='radio' value={value} name={name} onChange={onChange} />
      {label}
    </label>
  );
};

export default RadioItem;
