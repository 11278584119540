import React from 'react';
import Layout from '../../components/layout/Layout';
import useCountry from '../../hooks/countryHook';
import { Container, Row, Col } from 'react-bootstrap';

import './NotFound.scss';

const NotFound = ({ history }) => {
  const { t } = useCountry();

  const parsedContent = () => {
    return t('not-found.content')
      .split('\n')
      .map((str, i) => <p key={i}>{str}</p>);
  };

  return (
    <Layout>
      <Container>
        <Row>
          <Col className='col-12 action-bar row colContainer'>
            <Col style={{ padding: '5%', height: '30vh' }}>
              <div style={{ textAlign: 'center' }}>
                <h1 style={{ fontSize: '2.3rem' }}>{t('not-found.title')}</h1>
                <h2 style={{ fontSize: '1.8rem', marginTop: 10 }}>{t('not-found.sub-title')}</h2>
                <div style={{ fontSize: 14, marginTop: 10 }}>{parsedContent()}</div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <button
                  className='button button-primary button-primary-large btnReservar'
                  onClick={() => history.push('/mas')}
                >
                  {t('not-found.back-button-text')}
                </button>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default NotFound;
