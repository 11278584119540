import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import moment from 'moment';

import 'moment/locale/es';
// import './styles/Falabella.css'
import './styles/bootstrap.min.css';
import './styles/bootstrapOverride.scss';
import './styles/globals.scss';

moment.locale('es');

ReactDOM.render(<App />, document.getElementById('root'));
