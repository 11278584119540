import React from 'react';
import { Form } from 'react-bootstrap';
import './styles.scss';

const index = ({ checked, image, description, onClick }) => {
  return (
    <div className='custom-styling-radio__wrapper'>
      <div className='custom-styling-radio__image-wrapper'>
        <Form.Check type='checkbox' className='custom-checkbox'>
          <Form.Check.Input
            id={description.replace(/\s/i, '_')}
            className='custom-control-input'
            type='checkbox'
            checked={checked}
            onChange={onClick}
          />
          <Form.Check.Label htmlFor={description.replace(/\s/i, '_')} className='custom-control-label'>
            <img src={image} alt='Silueta' className='custom-styling-radio__image' />
          </Form.Check.Label>
        </Form.Check>
      </div>
      <div className='custom-styling-radio__description'>{description}</div>
    </div>
  );
};

export default index;
