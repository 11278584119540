import React from 'react';
import './styles.scss';

const ErrorMessage = ({ show, message }) => (
  <span className={`inputs__error-message ${!show ? 'inputs__error-message--hidden' : ''}`}>
    <img src='/img/exclamation.svg' alt='' />
    {message}
  </span>
);

export default ErrorMessage;
