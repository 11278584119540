import { useEffect, useState } from 'react';
import axios from 'axios';
import { defaultHeader } from 'api';
import useCountry from './countryHook';
import { properties } from '../properties';

export const useLocations = (sku?: string) => {
  const { getLocationName, getCountryName } = useCountry();
  const [load, setLoaded] = useState<boolean>(true);
  const [locations, setLocations] = useState<any[]>([]);

  useEffect(() => {
    if (load) {
      const locationName = getLocationName();

      axios({
        url: `${properties.backendUrl}/client/parent/types`,
        params: {
          sku: sku || null,
          locationType: locationName,
          countryName: getCountryName().toUpperCase(),
        },
        method: 'GET',
        headers: defaultHeader(),
      }).then(async (response) => {
        let items = response.data.data;
        setLocations(items);
      });
    }

    return () => {
      setLoaded(false);
    };
  });

  return locations;
};
