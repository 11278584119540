import { ChileCode, PeruCode, ColombiaCode } from '../properties';

const phoneRegex_CL = /^[+]?[0-9]{11}$/;
const phoneRegex_CO = /^[+]?[0-9]{11,12}$/;
const phoneRegex_PE = /^[+]?[0-9]{11}$/;

const validatePhone = (phone: string, countryCode: string = 'es_CL'): boolean => {
  switch (countryCode) {
    case ChileCode:
      return phoneRegex_CL.test(phone);
    case ColombiaCode:
      return phoneRegex_CO.test(phone);
    case PeruCode:
      return phoneRegex_PE.test(phone);
    default:
      return false;
  }
};

export default validatePhone;
