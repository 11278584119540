import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SuccessWithForm from './SuccessWithForm';
import useCountry from '../../hooks/countryHook';
import Layout from '../../components/layout/Layout';
import SuccessWithoutForm from './SuccessWithoutForm';
import ExperiencesItem from '../../components/checkout/ExperiencesItem';
import AppointmentDetail from '../../components/checkout/AppointmentDetail';
import './styles.scss';

const defaultServiceInformation = {
  sku: '',
  serviceName: '',
  locationName: '',
  storeName: '',
  subLocationName: '',
  localName: '',
  professionalName: '',
  time: '',
  date: '',
  place: '',
  placeOfDelivery: '',
};

const experiences = ['prod5651918', 'prod5063656', 'prod1513296', 'prod2563558'];

const Checkout = ({ history }) => {
  const { t } = useCountry();
  const serviceInformation = history?.location?.state?.serviceInformation;

  const [formUrl, setFormUrl] = useState('');
  const [hasForm, setHasForm] = useState(false);
  const [appDetail, setAppDetail] = useState(defaultServiceInformation);

  useEffect(() => {
    const createUrl = () => {
      if (serviceInformation.appointment.formUrl) {
        let url = new URL(serviceInformation.appointment.formUrl);

        url.searchParams.set('email', btoa(serviceInformation.appointment.email));
        url.searchParams.set('name', btoa(serviceInformation.appointment.name));
        url.searchParams.set('phone', btoa(serviceInformation.appointment.phone));
        url.searchParams.set('dni', btoa(serviceInformation.appointment.dni));
        url.searchParams.set('clientId', btoa(serviceInformation.appointment.clientId));
        url.searchParams.set('appointmentId', btoa(serviceInformation.appointment.appointmentId));
        url.searchParams.set('referrer', 'WEB');
        
        setFormUrl(url);
        setHasForm(true);
      }
    };
    
    if (serviceInformation) {
      window.scrollTo(0, 0);
      let place = '';

      switch (serviceInformation.placeOfDelivery?.toUpperCase()) {
        case 'TIENDA':
          place = serviceInformation.storeName;
          break;
        case 'LOCAL':
          place = serviceInformation.localName;
          break;
        case 'ONLINE':
          place = 'Online';
          break;
        case 'DOMICILIO':
          place = serviceInformation.subLocationName;
          break;
        default:
          place = '';
          break;
      }

      let date = serviceInformation.date || '';
      let time = serviceInformation.time || '';
      const appointmentDate = moment(date);

      if (appointmentDate.isValid()) {
        date = appointmentDate.format(t('checkout.date-format'));
      }

      if (serviceInformation.blockText) {
        time = serviceInformation.blockText;
      }

      setAppDetail({
        ...serviceInformation,
        place: place,
        date: date,
        time: time,
      });
      createUrl();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceInformation]);

  return (
    <Layout>
      <div className='checkout__wrapper'>
        {hasForm ? <SuccessWithForm formUrl={formUrl} /> : <SuccessWithoutForm />}

        <AppointmentDetail {...appDetail} />

        <div className='checkout-experiences-list__wrapper'>
          <h2 className='checkout-experiences-list__title'>{t('checkout.text')}</h2>
          <div className='checkout-experiences-list__items'>
            {experiences.map(experience => (
              <ExperiencesItem key={experience} sku={experience} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Checkout;
