import React, { useEffect, useState } from 'react';
import useCountry from '../../hooks/countryHook';
import defaultDesktop from './img/banner_op_2.png';
import defaultMobile from './img/banner_op_2_mobile.png';

import './styles.scss';

const BannerTop = ({ bannerProps, display = true, isPlp = false }) => {
  const { t } = useCountry();
  const [desktopBanner, setDesktopBanner] = useState(defaultDesktop);
  const [mobileBanner, setMobileBanner] = useState(defaultMobile);
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (bannerProps && Object.keys(bannerProps).length) {
      if (bannerProps.imageBannerDesktopUrl) {
        setDesktopBanner(bannerProps.imageBannerDesktopUrl);
      }

      if (bannerProps.imageBannerMobileUrl) {
        setMobileBanner(bannerProps.imageBannerMobileUrl);
      }

      if (bannerProps.imageBannerUrl) {
        setUrl(bannerProps.imageBannerUrl);
      }
    }
  }, [bannerProps]);

  const anchorHandleClick = (e) => {
    if (!url) {
      e.preventDefault();
    }
  };

  return (
    <div className={`top-banner__wrapper ${display ? '' : 'top-banner__wrapper--hidden'}`}>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={url}
        style={{ cursor: url ? 'pointer' : 'default' }}
        onClick={anchorHandleClick}
      >
        <img
          src={desktopBanner}
          className={`top-banner__desktop ${isPlp ? 'top-banner__desktop--plp' : ''}`}
          alt={t('banner-top.title')}
        />
        <img
          src={mobileBanner}
          className={`top-banner__mobile ${isPlp ? 'top-banner__mobile--plp' : ''}`}
          alt={t('banner-top.title')}
        />
      </a>
    </div>
  );
};

export default BannerTop;
