import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../components/layout/Layout';
import InputText from '../../components/inputs/text';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from '../../components/SurveyItems/Button';
import CheckItem from '../../components/SurveyItems/Check';
import RadioItem from '../../components/SurveyItems/Radio';
import ServiceContext from '../../contexts/serviceContext';
import TextareaItem from '../../components/SurveyItems/Textarea';
import TinyCheckItem from '../../components/SurveyItems/TinyCheck';
import { getFormById, getFormHome } from '../../utils/surveyForms';
import CustomStylingRadio from '../../components/SurveyItems/Styling/CustomRadio';

import './styles.scss';

const LeftArrow = () => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.333 9.66646H5.43965L11.9463 3.1598C12.4663 2.6398 12.4663 1.78646 11.9463 1.26646C11.4263 0.746465 10.5863 0.746465 10.0663 1.26646L1.27965 10.0531C0.759648 10.5731 0.759648 11.4131 1.27965 11.9331L10.0663 20.7198C10.5863 21.2398 11.4263 21.2398 11.9463 20.7198C12.4663 20.1998 12.4663 19.3598 11.9463 18.8398L5.43965 12.3331H20.333C21.0663 12.3331 21.6663 11.7331 21.6663 10.9998C21.6663 10.2665 21.0663 9.66646 20.333 9.66646Z'
      fill='#5D606F'
    />
  </svg>
);

const SurveyPage = ({ history }) => {
  const [step, setStep] = useState(0);
  const [home, setHome] = useState(null);
  const [values, setValues] = useState({});
  const [survey, setSurvey] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const { checkoutInformation, setSurveyAnswers } = useContext(ServiceContext);

  useEffect(() => {
    makeSurvey();
  }, []);

  useEffect(() => {
    if (!checkoutInformation || !Object.keys(checkoutInformation).length) {
      history.push('/mas');
    }
  }, [checkoutInformation, history]);

  useEffect(() => {
    if (survey && survey.steps.length) {
      const currentStep = survey.steps.find((s) => s.position === step);

      if (currentStep) {
        setDisabledButton(currentStep.disabledButton);
      }
    }
  }, [step, survey]);

  const makeSurvey = () => {
    const currentFormId = 'styling';
    const form = getFormById(currentFormId);
    setSurvey(form);

    const formHome = getFormHome(currentFormId);
    setHome(formHome);
  };

  const setAnswers = () => {
    const answers = {
      appointmentId: checkoutInformation.appointmentId || '',
      surveyType: 'personalShopper',
      personalShopper: {
        ...values,
        asesoryDetail: values.asesoryDetail || '',
        bodyFocusDetail: values.bodyFocusDetail || '',
        otherBrandsForAsesory: values.otherBrandsForAsesory || '',
      },
    };

    setSurveyAnswers(answers);

    history.push({ pathname: '/mas/contact-information' });
  };

  return (
    <Layout>
      {step === 0 ? (
        <div>
          <div className='survey-page__home-wrapper'>
            <h1 className='survey-page__title'>{home?.title || ''}</h1>
            <h2 className='survey-page__subtitle'>{home?.subTitle || ''}</h2>

            <img className='survey-page__logo' src={home?.logo || ''} alt='Logo' />

            <div>
              <Button text='Comenzar' onClick={() => setStep((old) => old + 1)} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='survey-page__progress-wrapper'>
            <div className='survey-page__progress-text'>
              <i
                className='survey-page__back-step'
                onClick={() => {
                  if (step > 1) setStep((old) => old - 1);
                }}
              >
                <LeftArrow />
              </i>

              <span>{`${step} de ${survey?.steps.length || 1}`}</span>
            </div>

            <ProgressBar now={step} max={survey?.steps.length || 1} />
          </div>

          {survey &&
            survey.steps
              .filter((s) => s.position === step)
              .map((currentStep, stepIndex) => (
                <div key={stepIndex} className={`survey-page__wrapper ${currentStep.customClass?.join(' ') || ''}`}>
                  <h1 className='survey-page__step-title'>{currentStep.title}</h1>
                  <h2 className='survey-page__step-subtitle'>{currentStep.subTitle}</h2>
                  <span className='survey-page__step-information'>{currentStep.information}</span>

                  <br />

                  {currentStep.sections
                    .sort((a, b) => a.position - b.position)
                    .filter((s) => !Object.keys(s).includes('onlyFor') || s.onlyFor.includes(values.gender || ''))
                    .map((section, sectionIndex) => (
                      <div key={sectionIndex}>
                        <h3 className='survey-page__section-title'>
                          {section.title}
                          {section.image && (
                            <img className='survey-page__section-image' src={section.image} alt='Imagen' />
                          )}
                        </h3>
                        <h4 className='survey-page__section-subtitle'>{section.subTitle}</h4>

                        <div className={`survey-page__sections-wrapper ${section.customSectionClass?.join(' ') || ''}`}>
                          {section.options.map((option, optionIndex) => {
                            switch (option.type) {
                              case 'radio':
                                return (
                                  <RadioItem
                                    key={optionIndex}
                                    value={option.value}
                                    name={currentStep.title.replace(/\s/g, '_')}
                                    onChange={() => {
                                      setValues((v) => ({
                                        ...v,
                                        [section.id]: option.value,
                                      }));

                                      if (!currentStep.showButton) {
                                        setStep((old) => old + 1);
                                      }
                                    }}
                                    label={option.label}
                                  />
                                );
                              case 'tiny-radio':
                                return (
                                  <RadioItem
                                    key={optionIndex}
                                    value={option.value}
                                    name={currentStep.title.replace(/\s/g, '_')}
                                    checked={values[section.id] === option.value}
                                    onChange={() => {
                                      setValues((v) => {
                                        const newValues = {
                                          ...v,
                                          [section.id]: option.value,
                                        };

                                        setDisabledButton(!currentStep.onValidate(newValues));

                                        return newValues;
                                      });
                                    }}
                                    label={option.label}
                                    tiny
                                  />
                                );
                              case 'check':
                                return (
                                  <CheckItem
                                    key={optionIndex}
                                    value={option.value}
                                    label={option.label}
                                    onChange={(checked) => {
                                      let checkList = values[section.id];

                                      if (!checkList) {
                                        checkList = [option.value];
                                      } else if (checked) {
                                        checkList.push(option.value);
                                      } else {
                                        checkList = checkList.filter((c) => c !== option.value);
                                      }

                                      setValues((v) => ({
                                        ...v,
                                        [section.id]: checkList,
                                      }));

                                      if (currentStep.onValidate(checkList)) {
                                        setDisabledButton(false);
                                      } else {
                                        setDisabledButton(true);
                                      }
                                    }}
                                  />
                                );
                              case 'tiny-check':
                                return (
                                  <TinyCheckItem
                                    key={optionIndex}
                                    value={option.value}
                                    label={option.label}
                                    checked={values[section.id] === option.value}
                                    onChange={(checked) => {
                                      setValues((v) => {
                                        const newValues = {
                                          ...v,
                                          [section.id]: checked ? option.value : '',
                                        };

                                        setDisabledButton(!currentStep.onValidate(newValues));

                                        return newValues;
                                      });
                                    }}
                                  />
                                );
                              case 'input':
                                return (
                                  <InputText
                                    key={optionIndex}
                                    label={option.label}
                                    handleChange={(value) => {
                                      if (currentStep.onValidate(value)) {
                                        setValues((v) => ({
                                          ...v,
                                          [section.id]: value,
                                        }));

                                        setDisabledButton(false);
                                      } else {
                                        setValues((v) => ({
                                          ...v,
                                          [section.id]: null,
                                        }));
                                        setDisabledButton(true);
                                      }
                                    }}
                                  />
                                );
                              case 'textarea':
                                return (
                                  <TextareaItem
                                    key={optionIndex}
                                    placeHolder={option.placeholder}
                                    onChange={(e) => setValues((v) => ({
                                      ...v,
                                      [section.id]: e.target.value || '',
                                    }))}
                                    maxLength={200}
                                  />
                                );
                              case 'customStylingRadio':
                                return (
                                  <CustomStylingRadio
                                    key={optionIndex}
                                    checked={values[section.id] === option.value}
                                    image={option.image}
                                    description={option.label}
                                    onClick={() => {
                                      setValues((v) => ({
                                        ...v,
                                        [section.id]: option.value,
                                      }));

                                      setDisabledButton(false);
                                    }}
                                  />
                                );
                              default:
                                return <></>;
                            }
                          })}
                        </div>
                      </div>
                    ))}

                  <div className='survey-page__button-wrapper'>
                    {currentStep.showButton && (
                      <Button
                        text={survey?.steps.length === step ? 'Enviar respuestas' : 'Continuar'}
                        onClick={() => {
                          if (step < (survey?.steps.length || 1)) setStep((old) => old + 1);
                          else if (survey?.steps.length === step) setAnswers();
                        }}
                        disabled={disabledButton}
                      />
                    )}
                  </div>
                </div>
              ))}
        </div>
      )}
    </Layout>
  );
};

export default SurveyPage;
