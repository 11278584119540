const common = require('./common.json');
const PDP = require('./pdp.json');
const PLP = require('./plp.json');
const checkout = require('./checkout.json');
const contactInformation = require('./contactInformation.json');
const notFound = require('./notFound.json');
const updateAppointment = require('./updateAppointment.json');

const i18n = {
  i18n: {
    ...common,
    ...PDP,
    ...PLP,
    ...checkout,
    ...contactInformation,
    ...notFound,
    ...updateAppointment,
  },
};

export default i18n;
