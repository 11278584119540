import React from 'react';
import PropTypes from 'prop-types';
import { parseServiceName, capitalizeFirstLetter } from '../../../utils/strings';

import './styles.scss';

const AppointmentDetail = props => (
  <div className='ap-detail__wrapper'>
    <h2 className='ap-detail__title'>Detalles de la Cita</h2>
    <div className='ap-detail__body'>
      <div className='ap-detail__section'>
        <span className='ap-detail__section-title'>Servicio</span>
        <span className='ap-detail__section-value'>{props.serviceName}</span>
      </div>

      <div className='ap-detail__section'>
        <span className='ap-detail__section-title'>Fecha</span>
        <span className='ap-detail__section-value'>{capitalizeFirstLetter(props.date)}</span>
      </div>

      {props.time && (
        <div className='ap-detail__section'>
          <span className='ap-detail__section-title'>Horario</span>
          <span className='ap-detail__section-value'>{props.time}</span>
        </div>
      )}

      <div className='ap-detail__section'>
        <span className='ap-detail__section-title'>Lugar</span>
        <span className='ap-detail__section-value' id='deliveryPlace'>
          {parseServiceName(props.place)}
        </span>
      </div>

      {props.professionalName && (
        <div className='ap-detail__section'>
          <span className='ap-detail__section-title'>Servicio entregado por</span>
          <span className='ap-detail__section-value'>{parseServiceName(props.professionalName)}</span>
        </div>
      )}
    </div>
  </div>
);

AppointmentDetail.propTypes = {
  serviceName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  professionalName: PropTypes.string,
};

export default AppointmentDetail;
