import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import './styles.scss';

const CheckboxInput = props => {
  const { id, label, handleCheck, displayError = false, errorMessage = '' } = props;
  const [checked, setChecked] = useState(false);

  const onCheck = () => {
    const isChecked = !checked;
    setChecked(isChecked);

    if (handleCheck) {
      handleCheck(isChecked);
    }
  };

  return (
    <div>
      <Form.Check type='checkbox' className='custom-checkbox'>
        <Form.Check.Input id={id} className='custom-control-input' type='checkbox' value={checked} onChange={onCheck} />
        <Form.Check.Label htmlFor={id} className='custom-control-label'>
          {label}
        </Form.Check.Label>
      </Form.Check>
      <span className={`custom-checkbox__error-message ${!displayError || checked ? 'hidden' : ''}`}>
        <img src='/img/exclamation.svg' alt='' />
        {errorMessage}
      </span>
    </div>
  );
};

export default CheckboxInput;
