import React, { useState, useEffect } from 'react';
import Routes from './Routes';
import { Helmet } from 'react-helmet';
import ServiceContext from './contexts/serviceContext';

/* Detect real vh in mobile with url bar. If you need this code in other part, please move this fragment to root document */
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

const App = () => {
  const analyticsUrl = process.env.REACT_APP_ANALITYCS_URL || '';
  const persistedServiceData = JSON.parse(localStorage.getItem('serviceData'));
  const [checkoutInformation, setCheckoutInformation] = useState({});
  const [surveyAnswers, setSurveyAnswers] = useState({});
  const [serviceData, setServiceData] = useState(persistedServiceData);

  useEffect(() => {
    localStorage.setItem('serviceData', JSON.stringify(serviceData));
  }, [serviceData]);

  return (
    <ServiceContext.Provider
      value={{
        serviceData,
        setServiceData,
        checkoutInformation,
        setCheckoutInformation,
        surveyAnswers,
        setSurveyAnswers,
      }}
    >
      <Helmet>
        <script src={analyticsUrl} async></script>
      </Helmet>
      <Routes />
    </ServiceContext.Provider>
  );
};

export default App;
