import React from 'react';
import useCountry from 'hooks/countryHook';

interface iProps {
  formUrl: string;
}

const SuccessWithForm = ({ formUrl }: iProps) => {
  const { t } = useCountry();
  return (
    <div className='checkout-header'>
      <h2 className='checkout-header__title'>{t('checkout.success-with-form.title')}</h2>
      <div className='checkout-header__subtitle'>{t('checkout.success-with-form.sub-title')}</div>
      <div className='checkout-header__button'>
        <a href={formUrl}>{t('checkout.success-with-form.link-text')}</a>
        <span className='checkout-header__button-info'>{t('checkout.success-with-form.button-info-text')}</span>
      </div>
    </div>
  );
};

export default SuccessWithForm;
