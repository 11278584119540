import React, { useState, useEffect } from 'react';
import moment from "moment";
import Api from '../../api';
import Swal from 'sweetalert2';
import ErrorMessage from '../inputs/errorMessage';
import LoadingOverlay from 'react-loading-overlay';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import './CalendarMobile.scss';

LoadingOverlay.propTypes = undefined; // Fix dead repo issue


export const customDayPropGetter = (date, calendarData, selectedDate) => {
  const property = { className: 'non-available-day' };
  const listOfFromDays =
    calendarData && calendarData.availabilityBlocks
      ? calendarData.availabilityBlocks.map(d => d.from.slice(0, 10))
      : [];
  const cellDateFormated = moment(date).format('YYYY-MM-DD');
  const cellDateIsEqualToSelectedDate = cellDateFormated === moment(selectedDate).format('YYYY-MM-DD');

  if (cellDateIsEqualToSelectedDate && listOfFromDays.includes(cellDateFormated)) {
    property.className = 'selected-day-selectable';
  } else if (cellDateIsEqualToSelectedDate) {
    property.className = 'selected-day-non-selectable';
  } else if (listOfFromDays.includes(cellDateFormated)) {
    property.className = 'available-day';
  }

  return property;
};

const defaultSelectedDate = new Date();

const CalendarMobile = props => {
  const { filters, professional, setAvailabilityInfo, maydayIntegrationIsActive, readyToSearch } = props;
  const [skuCalendarData, setSkuCalendarData] = useState({});
  const localizer = momentLocalizer(moment);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [initialDate, setInitialDate] = useState(moment());
  const [errorMessage, setErrorMessage] = useState('');
  const clickedInDateAction = 'DATE';

  useEffect(() => {
    const getData = async () => {
      setAvailabilityInfo({});
      setLoading(true);

      const currentDate = moment();
      if (maydayIntegrationIsActive) {
        currentDate.add(2, 'days');
      }

      const lastDay = currentDate.toDate();
      lastDay.setDate(1);
      lastDay.setMonth(lastDay.getMonth() + 1);
      lastDay.setDate(lastDay.getDate() - 1);

      const availabilitiesFromDate = currentDate.toISOString().split('T')[0].replace(/-/g, '');

      const data = {
        storeId: filters.store,
        productSKU: filters.sku,
        professionalId: professional,
        availabilitiesFromDate: availabilitiesFromDate,
        availabilitiesToDate: lastDay.toISOString().split('T')[0].replace(/-/g, ''),
        locationId: filters.subLocation,
        localId: filters.local,
      };

      try {
        const response = await Api.getServiceAvailabilityByDate(data, filters.type);
        if (response.smallPrint && response.smallPrint.noAvailabilityBlocksMessage && !response.availabilityBlocks.length) {
          setErrorMessage(response.smallPrint.noAvailabilityBlocksMessage);
        }

        if (response.timeUnit) {
          setSkuCalendarData(response);

          const cellDateFormated = moment(defaultSelectedDate).format('YYYY-MM-DD');
          const listOfFromDays = response.availabilityBlocks ? response.availabilityBlocks.map(d => d.from.slice(0, 10)) : [];

          if (listOfFromDays.length) {
            const firstDate = listOfFromDays[0];
            const tmpDate = moment(firstDate, 'YYYY-MM-DD');
            setInitialDate(tmpDate);
            fixStyle(response.availabilityBlocks);
          }

          if (listOfFromDays.includes(cellDateFormated)) {
            props.setAvailabilityInfo(response);
          }
        }

        if (props.setServiceDailyDisplayHours && response.serviceDailyDisplayHours) {
          props.setServiceDailyDisplayHours(response.serviceDailyDisplayHours);
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message,
          confirmButtonText: 'Cerrar',
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonColor: '#aad500',
          customClass: {
            confirmButton: 'confirm-button-class',
            popup: 'popup-class',
          },
        });
      }
      setLoading(false);
    };

    if (readyToSearch) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, professional, setAvailabilityInfo, readyToSearch]);

  const fixStyle = (availabilityBlocks = []) => {
    const uniqueDays = [];

    availabilityBlocks.forEach((block) => {
      const formatedBlock = block.from.slice(8, 10);

      if (!uniqueDays.includes(formatedBlock)) {
        uniqueDays.push(formatedBlock);
      }
    });

    if (uniqueDays.length) {
      const buttons = document.getElementsByClassName('rbc-date-cell');
      for (var i = 0; i < buttons.length; i++) {
        if (!buttons[i].classList.contains('rbc-off-range')) {
          const buttonDay = buttons[i].children[0].innerHTML;

          if (!uniqueDays.includes(buttonDay)) {
            buttons[i].children[0].classList.add('without-data-button')
          }
        }
      }
    }
  }

  const fixSelectedDayStyle = (date) => {
    // Remove last button
    const prevButtons = document.getElementsByClassName('selected-button');
    for (var pi = 0; pi < prevButtons.length; pi++) {
      prevButtons[pi].classList.remove('selected-button');
    }

    // Set new btn
    if (!date) {
      return;
    }

    const dateToFix = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const buttons = document.getElementsByClassName('rbc-date-cell');
    for (var bi = 0; bi < buttons.length; bi++) {
      if (buttons[bi].children[0].innerHTML === dateToFix.toString()) {
        buttons[bi].children[0].classList.add('selected-button');
        break;
      }
    }
  }

  const getSkuData = async (date, action = '') => {
    if (action === clickedInDateAction) {
      const cellDateFormated = moment(date).format('YYYY-MM-DD');
      const listOfFromDays =
        skuCalendarData && skuCalendarData.availabilityBlocks
          ? skuCalendarData.availabilityBlocks.map(d => d.from.slice(0, 10))
          : [];

      if (!listOfFromDays.includes(cellDateFormated)) {
        props.setAvailabilityInfo({});
        setSelectedDate(null);
        fixSelectedDayStyle(null);
        return;
      }

      setSelectedDate(date);
      props.setSelectedDate(date);
      fixSelectedDayStyle(date);
    } else {
      // Clear blocks
      props.setAvailabilityInfo({});
      setSelectedDate(null);
      fixSelectedDayStyle(null);
      const tmpDate = moment(date);
      setInitialDate(tmpDate);
    }

    setLoading(true);

    try {
      const firstDay = new Date(date.getTime());
      const lastDay = new Date(date.getTime());
      const sixMonthsLimit = new Date();

      sixMonthsLimit.setMonth(sixMonthsLimit.getMonth() + 6);
      firstDay.setDate(1);

      const today = new Date();

      if (new Date(firstDay.getTime()) < today.getTime() || maydayIntegrationIsActive) {
        const currentDate = moment();
        if (maydayIntegrationIsActive) {
          currentDate.add(2, 'days');
        }

        firstDay.setDate(currentDate.format('D'));
      }

      lastDay.setDate(1);
      lastDay.setMonth(lastDay.getMonth() + 1);
      lastDay.setDate(lastDay.getDate() - 1);

      const data = {
        storeId: filters.store,
        productSKU: filters.sku,
        professionalId: professional,
        availabilitiesFromDate: firstDay.toISOString().split('T')[0].replace(/-/g, ''),
        availabilitiesToDate: lastDay.toISOString().split('T')[0].replace(/-/g, ''),
        locationId: filters.subLocation,
        localId: filters.local,
      };

      const response = await Api.getServiceAvailabilityByDate(data, filters.type);

      if (response.timeUnit) {
        if (response && response.availabilityBlocks.length) {
          const firstDate = response.availabilityBlocks[0];
          const tmpDate = moment(firstDate.from, 'YYYY-MM-DD');
          setInitialDate(tmpDate);
          fixStyle(response.availabilityBlocks);
        }

        setSkuCalendarData(response);

        if (action === clickedInDateAction) {
          props.setAvailabilityInfo(response);
          document.getElementById("modal-step__blocks").scrollIntoView({behavior: 'smooth'}, true)
        }
      }

      if (props.setServiceDailyDisplayHours && response.serviceDailyDisplayHours) {
        props.setServiceDailyDisplayHours(response.serviceDailyDisplayHours);
      }
    } catch { }

    setLoading(false);
  };

  const CustomToolbar = ({ date, onNavigate, label }) => {
    const selectedMonth = moment(date).format('MM-YY');
    const currentMonth = moment().format('MM-YY');

    const navigate = action => {
      onNavigate(action);
    };

    return (
      <div className='rbc-toolbar'>
        <span className='rbc-btn-group'>
          <button
            type='button'
            onClick={navigate.bind(null, 'PREV')}
            style={selectedMonth === currentMonth ? { display: 'none' } : {}}
          >
            <img src='/img/prev_icon.svg' alt='>' />
          </button>
        </span>

        <span className='rbc-toolbar-label'>{label}</span>

        <span className='rbc-btn-group'>
          <button type='button' onClick={navigate.bind(null, 'NEXT')}>
            <img src='/img/next_icon.svg' alt='>' />
          </button>
        </span>
      </div>
    );
  };

  return (
    <div className='container-calendar calendar-mobile'>
      <LoadingOverlay active={loading} spinner>
        <ErrorMessage show={props.displayError || !!errorMessage} message={errorMessage ? errorMessage : props.errorMessage} />

        <Calendar
          localizer={localizer}
          startAccessor='start'
          endAccessor='end'
          messages={{
            today: 'Hoy',
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
            noEventsInRange: 'No hay eventos para este rango.',
          }}
          date={initialDate.toDate()}
          events={[]}
          dayPropGetter={date => customDayPropGetter(date, skuCalendarData, selectedDate)}
          slotPropGetter={date => customDayPropGetter(date, skuCalendarData, selectedDate)}
          onNavigate={(date, _view, action) => readyToSearch && getSkuData(date, action)}
          views={{ month: true }}
          style={{ height: 350, maxWidth: 350, margin: 'auto' }}
          className={!readyToSearch ? 'disabled-mb-calendar' : ''}
          components={{ toolbar: CustomToolbar }}
        />
      </LoadingOverlay>
    </div>
  );
};

export default CalendarMobile;
