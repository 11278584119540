import { createContext } from "react";

const ServiceContext = createContext({
  serviceData: {},
  setServiceData: () => {},
  appointmentData: {},
  checkoutInformation: {},
  setCheckoutInformation: () => {},
  surveyAnswers: {},
  setSurveyAnswers: () => {},
});

export default ServiceContext;
