import React from 'react';
import ServicePrice from '../price';
import useCountry from 'hooks/countryHook';
import './style.scss';

interface iProps {
  brand: string,
  name: string,
  sku: string,
  price: number,
  displayPrice: boolean,
  pricePrefix?: string
}

const Title = ({ brand, name, sku, price, displayPrice, pricePrefix = '' }: iProps) => {
  const { t } = useCountry();

  return (
    <div className='service-title'>
      <span className='service-title__brand'>{brand}</span>
      <span className='service-title__name'>{name}</span>

      {displayPrice && <ServicePrice price={price} prefix={pricePrefix} />}

      <span className='service-title__sku'>{`${t('plp.skuText')}: ${sku}`}</span>
    </div>
  );
};

export default Title;
