import React, { useEffect, useState } from 'react';
import api from '../../api';
import moment from 'moment';
import useQuery from '../../hooks/useQuery';
import useCountry from '../../hooks/countryHook';
import AlertSvg from '../../img/alert-error.svg';
import Layout from '../../components/layout/Layout';
import ExperiencesItem from '../../components/checkout/ExperiencesItem';
import './styles.scss';

const experiences = ['prod5651918', 'prod5063656', 'prod1513296', 'prod2563558'];

const Confirm = () => {
  const { t } = useCountry();
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('success');
  const [requestHasBeenSended, setRequestHasBeenSended] = useState(false);
  const [statusDescription, setStatusDescription] = useState('badRequest');
  const [printedDate, setPrintedDate] = useState('--');
  const [printedTime, setPrintedTime] = useState('--');
  const fullDateFormat = t('confirm-appointment.full-date-format');
  const dateFormat = t('confirm-appointment.date-format');
  const hourFormat = t('confirm-appointment.hour-format');

  const statusResponse = {
    unauthorized: t('confirm-appointment.errors.unauthorized'),
    badRequest: t('confirm-appointment.errors.bad-request'),
  };

  useEffect(() => {
    const printData = async () => {
      let parsedData = {
        date: '--',
        time: '--',
      };
      let hasAnyProblem = false;

      const timeUnit = await query.get('timeUnit');
      if (timeUnit) {
        const datetime = await query.get('datetime');
        const from = await query.get('from');
        const to = await query.get('to');

        if ((timeUnit === 'D' || timeUnit === 'W') && from) {
          const fromDate = moment(from);
          const dailyHours = await query.get('dailyHours');

          if (fromDate.isValid() && dailyHours) {
            parsedData.date = fromDate.format(fullDateFormat);
            parsedData.time = dailyHours.replace('|', ' a ');
          } else {
            hasAnyProblem = true;
          }
        } else if (timeUnit === 'H' && datetime && from && to) {
          const datetimeObj = moment(datetime);

          if (datetimeObj.isValid()) {
            const fromDate = moment(`${datetimeObj.format(dateFormat)} ${from}`);
            const toDate = moment(`${datetimeObj.format(dateFormat)} ${to}`);

            if (fromDate.isValid() && toDate.isValid()) {
              parsedData.date = datetimeObj.format(fullDateFormat);
              parsedData.time = `${fromDate.format(hourFormat)} a ${toDate.format(hourFormat)}`;
            } else {
              hasAnyProblem = true;
            }
          } else {
            hasAnyProblem = true;
          }
        } else {
          hasAnyProblem = true;
        }
      } else {
        hasAnyProblem = true;
      }

      if (hasAnyProblem) {
        setStatus('error');
      }

      setPrintedDate(parsedData.date);
      setPrintedTime(parsedData.time);
      setLoading(false);
    };

    const confirmAppointment = async () => {
      const rawToken = await query.get('token');

      if (!rawToken) {
        setStatus('error');
        setStatusDescription('unauthorized');
        setLoading(false);
        return;
      }

      if (loading) {
        if (!requestHasBeenSended) {
          if (rawToken) {
            setRequestHasBeenSended(true);
            const req = await api.confirmEmailAppointment(rawToken.split(' ')[0]);
            if (req && req.respCode === 200) {
              printData();
              setStatus('success');
              setStatusDescription('default');
            } else {
              setStatus('error');
              req.respCode === 401 ? setStatusDescription('unauthorized') : setStatusDescription('badRequest');
            }
          } else {
            setStatus('error');
            setStatusDescription('unauthorized');
          }
        }

        setLoading(false);
      }
    };

    if (query) {
      confirmAppointment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  if (loading) {
    return (
      <div className='update-appointment__wrapper'>
        <div className='update-appointment__card'>{t('confirm-appointment.loading-text')}</div>
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className='update-appointment__card'>
        <img className='update-appointment__icon' src={AlertSvg} alt='Check' />
        <div className='update-appointment__text'>{t('confirm-appointment.error-title')}</div>
        <div className='update-appointment__text'>{statusResponse[statusDescription]}</div>
      </div>
    );
  }

  return (
    <Layout>
      <div className='upd-app__wrapper'>
        <h1 className='upd-app__title'>{t('confirm-appointment.success-title')}</h1>
        <h2 className='upd-app__subtitle'>{t('confirm-appointment.success-subtitle')}</h2>

        <div className='upd-app__details'>
          <div className='upd-app__details-title'>
            <span>{t('confirm-appointment.details.title')}</span>
          </div>
          <div className='upd-app__details-content'>
            <div className='upd-app__details-section'>
              <span className='upd-app__details-section-title'>{t('confirm-appointment.details.date')}</span>
              <span className='upd-app__details-section-value'>{printedDate}</span>
            </div>

            <div className='upd-app__details-section'>
              <span className='upd-app__details-section-title'>{t('confirm-appointment.details.fantasyId')}</span>
              <span className='upd-app__details-section-value'>{query.get('fantasyId') || '--'}</span>
            </div>

            <div className='upd-app__details-section'>
              <span className='upd-app__details-section-title'>{t('confirm-appointment.details.time')}</span>
              <span className='upd-app__details-section-value'>{printedTime}</span>
            </div>

            <div className='upd-app__details-section'>
              <span className='upd-app__details-section-title'>{t('confirm-appointment.details.location')}</span>
              <span className='upd-app__details-section-value upd-app__details-section-value--capitalize'>
                {query.get('ubication')?.toLowerCase() || 'Online'}
              </span>
            </div>
          </div>
        </div>

        <div className='upd-app-experiences-list__wrapper'>
          <h2 className='upd-app-experiences-list__title'>{t('confirm-appointment.recommended-services-title')}</h2>
          <div className='upd-app-experiences-list__items'>
            {experiences.map((experience) => (
              <ExperiencesItem key={experience} sku={experience} mkid={'confirm'} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Confirm;
