import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Modal, Container, Row, Spinner } from 'react-bootstrap';

const ModalTerms = ({ show, onHide, content }) => {
  const [terms, setTerms] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (content) {
      setIsLoading(true);
      const contentInParagraphs = content.split('\n').map((str, i) => <p key={i}>{str}</p>);
      setTerms(contentInParagraphs);
      setIsLoading(false);
    }
  }, [content]);

  useEffect(() => {
    if (show && !terms && !content) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Lo sentimos, no fue posible cargar los términos y condiciones.',
        confirmButtonText: 'Cerrar',
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonColor: '#aad500',
        customClass: {
          confirmButton: 'confirm-button-class',
          popup: 'popup-class',
        },
      });

      onHide();
    }
  }, [show, onHide, terms, content]);

  return (
    <Modal show={show && !!terms} onHide={onHide}>
      <Modal.Header closeButton style={{ borderBottom: 0 }}>
        <Modal.Title>Términos y Condiciones</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container style={{ backgroundColor: '#FFF' }}>
          {isLoading ? (
            <Row>
              <Spinner animation='grow' role='status' style={{ margin: 'auto', color: '#8fca00' }}>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            </Row>
          ) : (
            <>
              <Row>{terms}</Row>
              <Row>
                <button
                  type='button'
                  id='btnReservar'
                  className='button button-primary button-primary-large'
                  style={{ height: '40px', margin: 'auto' }}
                  onClick={onHide}
                >
                  Cerrar
                </button>
              </Row>
            </>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalTerms;
