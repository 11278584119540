import React, { useEffect, useState } from 'react';
import api from '../../api';
import PropTypes from 'prop-types';
import useCountry from '../../hooks/countryHook';
import { Dropdown, Form, Col } from 'react-bootstrap';
import { useLocations } from '../../hooks/useLocations';
import '../../styles/Falabella.css';

const ArrowDown = () => (
  <svg width='14px' viewBox='0 0 38 20' version='1.1'>
    <title />
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='icon/arrow/down' transform='translate(-19.000000, -29.000000)' fill='#BBBBBB'>
        <polygon
          id='Path'
          points='54.6186667 29.0541667 56.4331667 30.8686667 37.9968333 48.8458333 19.5636667 30.8686667 21.3781667 29.0541667 37.724189 44.9339231 38.275811 44.9339231'
        />
      </g>
    </g>
  </svg>
);

const LoadFilterStore = (props) => {
  const { t } = useCountry();
  const { inline, onChangeStore, dropdownFromDetailService, sku, __TEST_STORE__, __TEST_FUNC__ } = props;

  // useEffect(() => {
  //   localStorage.clear();
  // }, []);

  const locationTxt = localStorage.getItem('locationTxt');
  const storeTxt = localStorage.getItem('storeTxt');

  const [load, setLoaded] = useState(true);
  // Update
  const locations = useLocations(sku);
  const [optionProvince, setOptionProvince] = useState('');
  const [optionStore, setOptionStore] = useState('');

  const [selectLocationTxt, setSelectLocationTxt] = useState(
    locationTxt || t('pdp.filters.locationSelectorPlaceholder')
  );
  const [selectStoreTxt, setSelectStoreTxt] = useState(storeTxt || t('pdp.filters.storeSelectorPlaceholder'));

  const [showStore, setShowStore] = useState(!!locationTxt);

  // Remove this
  useEffect(() => {
    if (load || __TEST_STORE__) {
      setProvinces();
    }

    if (__TEST_FUNC__) {
      handleSetStore(__TEST_FUNC__);
    }
    return () => {
      setLoaded(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, locations]);

  // useEffect(() => {
  //   if (locationTxt) {
  //     const location = localStorage.getItem('province');
  //     handleGetStore(location, sku);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const setProvinces = () => {
    let items = !sku
      ? [
          <Dropdown.Item key={`1`} className='dropdown-item' id='Dropdown-1' onClick={handleSetProvince} value={'all'}>
            {t('pdp.filters.allOptions')}
          </Dropdown.Item>,
        ]
      : [];

    if (locations) {
      locations.forEach((element, index) => {
        items.push(
          <Dropdown.Item
            style={{ whiteSpace: 'normal' }}
            key={`prov${element.province}${index}`}
            id='Dropdown-1'
            className='dropdown-item filter-store-fromDetailForm-dropdown-item'
            onClick={handleSetProvince}
            value={element.idLocation}
          >
            {element?.name?.toUpperCase() || ''}
          </Dropdown.Item>
        );
      });
    }

    setOptionProvince(items);
  };

  const handleSetProvince = (e) => {
    const location = e.currentTarget.getAttribute('value');
    const locationTxt = e.currentTarget.textContent;
    const oldLocation = localStorage.getItem('location');

    if (oldLocation === location) return;

    setSelectStoreTxt(t('pdp.filters.storeSelectorPlaceholder'));
    localStorage.removeItem('store');
    localStorage.removeItem('storeTxt');

    if (location === 'all') {
      localStorage.removeItem('location');
      localStorage.removeItem('locationTxt');
      setSelectLocationTxt(t('pdp.filters.locationSelectorPlaceholder'));
      setOptionStore('');
      setShowStore(false);

      // * Get all stores again
      onChangeStore({
        filtroCategoria: {},
        filtroStore: {},
        paginatorCurrentPage: 1,
        qtyQuerySelect: 16,
      });

      return;
    } else {
      const obj = {};
      obj.location = location;
      onChangeStore(obj);
      setSelectLocationTxt(locationTxt);
      handleGetStore(location, sku);
    }

    // * For everyone to know which store was selected
    localStorage.setItem('location', location);
    localStorage.setItem('locationTxt', locationTxt);
  };

  const handleGetStore = async (locationId, _sku) => {
    setShowStore(false);

    try {
      const res = await api.getStores(locationId, _sku);
      let items = !_sku
        ? [
            <Dropdown.Item key={`1`} onClick={handleSetStore} locationid={locationId} value={'all'}>
              {t('pdp.filters.allOptions')}
            </Dropdown.Item>,
          ]
        : [];
  
      res.data.forEach((item, index) => {
        items.push(
          <Dropdown.Item
            id={`item_${item.idStore}`}
            style={{ whiteSpace: 'normal' }}
            key={`store${item.idStore}${index}`}
            onClick={handleSetStore}
            locationid={locationId}
            value={item.idStore}
          >
            {item.name.toUpperCase()}
          </Dropdown.Item>
        );
      });

      setOptionStore(items);
    } catch {}

    setShowStore(true);
  };

  const handleSetStore = (e) => {
    const store = e.currentTarget.getAttribute('value');
    const locationId = e.currentTarget.getAttribute('locationid');
    const newStoreTxt = e.currentTarget.textContent;

    setSelectStoreTxt(newStoreTxt);

    const obj = {};

    if (store === 'all') {
      localStorage.removeItem('store');
      localStorage.removeItem('storeTxt');
      setSelectStoreTxt(t('pdp.filters.storeSelectorPlaceholder'));

      obj.location = locationId;
      // * Get all stores again
      onChangeStore(obj);
      return;
    } else {
      obj.store = store;
      obj.location = locationId;
      onChangeStore(obj);
    }

    // * For everyone to know which store was selected
    localStorage.setItem('store', store);
    localStorage.setItem('storeTxt', newStoreTxt);
  };

  return (
    <div>
      {dropdownFromDetailService ? (
        <>
          <div className='location-title-container'>
            <img
              id='filter-store-fromDetailForm-img-location'
              className={'primary-color'}
              src={'/img/location_green.svg'}
              alt={''}
              style={{ height: 20 }}
            />
            <h6 id='h6-titulo' className='title-location'>
              ${t('pdp.filters.availableLocations')}
            </h6>
          </div>
          <Form
            id='filter-store-fromDetailForm'
            inline
            className={dropdownFromDetailService ? 'dropdownDetailService col-md-8 col-12' : 'top-filter'}
            style={{ paddingLeft: 0 }}
          >
            <Col md={12}>
              <Dropdown
                id='filter-store-fromDetailForm-dropdown'
                className='dropdown-wrapper'
                bsPrefix='dropdown-filter-store'
              >
                <Dropdown.Toggle
                  variant='dropdown-select'
                  width='220px'
                  id='filter-store-fromDetailForm-dropdown-toggle'
                >
                  <span
                    style={{
                      width: '190px',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                    id='filter-store-fromDetailForm-selprovincetitle'
                    width='220px'
                    className='copy3 primary'
                  >
                    {selectLocationTxt}
                  </span>
                  <ArrowDown />
                </Dropdown.Toggle>

                <Dropdown.Menu>{optionProvince}</Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={12}>
              <Dropdown
                className='dropdown-wrapper'
                style={{
                  display: showStore ? 'block' : 'none',
                }}
                bsPrefix='dropdown-filter-store'
                id='filter-store-fromDetailForm-dropdownStore'
              >
                <Dropdown.Toggle variant='dropdown-select' id='selstoredropdown'>
                  <span
                    style={{
                      width: '180px',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                    id='selstoretitle'
                    className='copy3 primary bold  '
                  >
                    {selectStoreTxt}
                  </span>
                  <ArrowDown />
                </Dropdown.Toggle>

                <Dropdown.Menu>{optionStore}</Dropdown.Menu>
              </Dropdown>
            </Col>
          </Form>
        </>
      ) : (
        <div style={{ display: 'flex' }} className='cc top-filter-container'>
          <img
            id='filter-store-img-location'
            className={'primary-color'}
            src={'/img/location_green.svg'}
            alt={''}
            style={{ height: 20 }}
          />
          <Form id='filter-store-form' inline className='top-filter'>
            <div
              id='filter-store-form-content'
              style={{
                display: 'flex',
                flexDirection: inline ? 'row' : 'column',
              }}
              className='cc'
            >
              <div>
                <Dropdown id='filter-store-form-dropdown' className='dropdown-wrapper'>
                  <Dropdown.Toggle
                    id='filter-store-form-dropdown-toggle'
                    variant='dropdown-select'
                    width='220px'
                  >
                    <span
                      style={{
                        width: '190px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                      width='220px'
                      className='copy3 primary'
                      id='filter-store-selprovincetitle'
                    >
                      {selectLocationTxt}
                    </span>
                    <ArrowDown />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{optionProvince}</Dropdown.Menu>
                </Dropdown>
              </div>

              <div
                id='dropdownStore-container'
                style={{
                  display: showStore ? 'block' : 'none',
                  marginTop: inline ? 0 : 20,
                  marginLeft: inline ? 25 : 0,
                }}
              >
                <Dropdown className='dropdown-wrapper' id='dropdownStore'>
                  <Dropdown.Toggle variant='dropdown-select' id='selstoredropdown'>
                    <span
                      style={{
                        width: '180px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                      id='selstoretitle'
                      className='copy3 primary'
                    >
                      {selectStoreTxt}
                    </span>
                    <ArrowDown />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{optionStore}</Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

LoadFilterStore.propTypes = {
  inline: PropTypes.bool,
  handleLoadServices: PropTypes.func,
  onChangeStore: PropTypes.func,
  sku: PropTypes.string,
  dropdownFromDetailService: PropTypes.bool,
};

export default LoadFilterStore;
