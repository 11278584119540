import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import api from '../../../api';
import InputText from '../../inputs/text';
import { useHistory } from 'react-router-dom';
import { PeruCode } from '../../../properties';
import ModalTerms from '../../modal/ModalTerms';
import validateDni from '../../../utils/dniUtil';
import CheckboxInput from '../../inputs/checkbox';
import useCountry from '../../../hooks/countryHook';
import validatePhone from '../../../utils/phoneUtil';
import ServiceContext from '../../../contexts/serviceContext';
import './styles.scss';

const onlyTextWithSpaces = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$/;
const onlyLettersAndNumbers = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s0-9,.]+$/;
// eslint-disable-next-line
const emailRegex = /^[\w\-_.]+@{1}[a-zA-Z0-9_-]+[.]{1}[a-zA-Z]+([.]{1}[a-zA-Z]+)?([.]{1}[a-zA-Z]+)?([.]{1}[a-zA-Z]+)?$/;

const BENEFIT_SKU = '5891241';

const defaultErrors = {
  name: false,
  lastname: false,
  dni: false,
  phone: false,
  address: false,
  email: false,
  ticket: false,
  checkTerms: false,
};

const CheckoutForm = ({ appointmentId }) => {
  const { t, getCountry, getPhoneCode } = useCountry();
  const router = useHistory();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValue] = useState({});
  const [formErrors, setFormErrors] = useState(defaultErrors);
  const { surveyAnswers, checkoutInformation } = useContext(ServiceContext);
  // Terms
  const [benefitTerms, setBenefitTerms] = useState(null);
  const [cmrpointsTerms, setCrmPointsTerms] = useState(null);
  const [appointmentsTerms, setAppointmentsTerms] = useState(null);
  // Modal
  const [modalTermsText, setModalTermsText] = useState('');
  const [displayModalTerms, setDisplayModalTerms] = useState(false);

  useEffect(() => {
    const getTermsAndConditions = async () => {
      const data = await api.getTerms();

      if (data && data.data.length) {
        const appointments = data.data.find((d) => d.type === 'appointments');
        const cmrPoints = data.data.find((d) => d.type === 'cmrpoints');
        const benefit = data.data.find((d) => d.type === 'benefit');

        if (appointments) {
          setAppointmentsTerms(appointments);
        }

        if (cmrPoints) {
          setCrmPointsTerms(cmrPoints);
        }

        if (benefit) {
          setBenefitTerms(benefit);
        }
      }
    };

    getTermsAndConditions();
  }, []);

  const validateForm = () => {
    setFormErrors(defaultErrors);
    const countryCode = getCountry();
    const phoneCode = getPhoneCode();

    let validForm = true;
    const tmpErrors = {
      ...defaultErrors,
    };

    if (!formValues.name) {
      validForm = false;
      tmpErrors.name = true;
    }

    if (!formValues.lastname) {
      validForm = false;
      tmpErrors.lastname = true;
    }

    if (!formValues.dni) {
      validForm = false;
      tmpErrors.dni = true;
    }

    //DNI validation. Verification digit automatically added if missing for PERU DNIs.
    const { isValid, fullDni } = validateDni(formValues.dni, countryCode);

    if (!isValid) {
      validForm = false;
      tmpErrors.dni = true;
    } else {
      formValues.dni = fullDni;
    }

    //Phone validation. Country phone code automatically added if missing.
    if (formValues.phone) {
      let phoneNumber = formValues.phone.startsWith('+') ? formValues.phone.substring(1) : formValues.phone;
      phoneNumber = phoneNumber.trim();

      const telephoneCodeWithoutPlus = phoneCode.substring(1);
      if (!phoneNumber.startsWith(telephoneCodeWithoutPlus)) {
        phoneNumber = `${phoneCode}${phoneNumber}`;
      }

      if (!phoneNumber || (phoneNumber && !validatePhone(`${phoneNumber}`, countryCode))) {
        validForm = false;
        tmpErrors.phone = true;
      } else {
        formValues.phone = phoneNumber;
      }
    } else {
      validForm = false;
      tmpErrors.phone = true;
    }

    if (!formValues.address && checkoutInformation.placeOfDelivery?.toUpperCase() === 'DOMICILIO') {
      validForm = false;
      tmpErrors.address = true;
    }

    if (!formValues.email) {
      validForm = false;
      tmpErrors.email = true;
    }

    if (!formValues.ticket && checkoutInformation.requirePrevSale) {
      validForm = false;
      tmpErrors.ticket = true;
    }

    if (!formValues.checkTerms) {
      validForm = false;
      tmpErrors.checkTerms = true;
    }

    setFormErrors(tmpErrors);
    return validForm;
  };

  const handleSeeTerms = (event, content) => {
    event.preventDefault();
    setModalTermsText(content);
    setDisplayModalTerms(true);
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    if (!isValid || loading) {
      return;
    }

    if (checkoutInformation.isMayday) {
      createMaydaySchedule();
    } else {
      confirmSchedule();
    }
  };

  const saveSurveyAnswers = async (clientId) => {
    if (surveyAnswers && Object.keys(surveyAnswers).length) {
      try {
        await api.saveSurvey({
          ...surveyAnswers,
          personalShopper: {
            ...surveyAnswers.personalShopper,
            clientId,
          },
        });
      } catch {
      }
    }
  };

  const confirmSchedule = async () => {
    setLoading(true);
    try {
      let dni = formValues.dni;
      const countryCode = getCountry();

      // Remove this when back fix the validator
      if (countryCode === PeruCode && !dni.includes('-')) {
        dni = `${dni.substring(0, dni.length - 1)}-${dni.substring(dni.length - 1)}`;
      }

      const confirmData = {
        client: {
          name: formValues.name,
          lastName: formValues.lastname,
          document: dni,
          phone: formValues.phone.replace('+', ''),
          direction: formValues.address || '',
          email: formValues.email,
          ticket: formValues.ticket || '',
          appointmentId: appointmentId,
        },
        tcAppointments: formValues.checkTerms
          ? {
            id: appointmentsTerms.id || '',
          }
          : null,
        tcCmrPoints: formValues.checkPromotion
          ? {
            id: cmrpointsTerms.id || '',
          }
          : null,
        tcBenefit: formValues.checkBenefit
          ? {
            id: benefitTerms.id || '',
          }
          : null,
      };

      // Confirm Appointment
      const response = await api.confirmAppointment(confirmData);
      verifyResponse(response);

      // Save Survey
      await saveSurveyAnswers(response.data.client.idClient);

      router.push({
        pathname: '/mas/checkout',
        state: {
          serviceInformation: {
            placeOfDelivery: checkoutInformation.placeOfDelivery,
            storeName: response.data.store?.name || '',
            localName: response.data.local?.name || '',
            subLocationName: response.data.subLocation?.name || '',
            serviceName: response.data.service.shortName,
            date: checkoutInformation.date,
            time: checkoutInformation.time,
            professionalName: checkoutInformation.professional?.text || '',
            appointment: {
              appointmentId: appointmentId,
              clientId: response.data.client.idClient,
              name: `${formValues.name} ${formValues.lastname}`,
              email: formValues.email,
              phone: formValues.phone,
              dni: formValues.dni,
              formUrl: response.data.preAppointmentSurveyUrl,
            },
            blockText: checkoutInformation.blockText || '',
          },
        },
      });
    } catch (error) {
      handleErrorModal(
        error,
        (result) => {
          if (result.isConfirmed) {
            router.push({
              pathname: `/mas/service-detail/${checkoutInformation.sku}`,
            });
          }
        },
        () => {
        },
        {},
      );
    }
    setLoading(false);
  };

  const createMaydaySchedule = async () => {
    setLoading(true);
    try {
      const confirmData = {
        sku: checkoutInformation.sku,
        client: {
          name: formValues.name,
          lastName: formValues.lastname,
          document: formValues.dni,
          phone: formValues.phone.replace('+', ''),
          direction: formValues.address || '',
          email: formValues.email,
          ticket: formValues.ticket || '',
        },

        datetime: checkoutInformation.datetime,
        from: checkoutInformation.from,
        to: checkoutInformation.to,

        locationId: checkoutInformation.location?.value || '',
        subLocationId: checkoutInformation.subLocation?.value || '',
        tcAppointments: formValues.checkTerms ? { id: appointmentsTerms.id || '' } : null,
        tcCmrPoints: formValues.checkPromotion ? { id: cmrpointsTerms.id || '' } : null,

        store: checkoutInformation.store?.value || '',
        local: checkoutInformation.local?.value || '',
        professional: checkoutInformation.professional?.value || '',
        blockText: checkoutInformation.blockText || '',
      };

      const response = await api.confirmMaydayAppointment(confirmData);
      verifyResponse(response);

      // Save Survey
      await saveSurveyAnswers(response.data.client.idClient);

      router.push({
        pathname: '/mas/checkout',
        state: {
          serviceInformation: {
            placeOfDelivery: checkoutInformation.placeOfDelivery,
            storeName: response.data.store?.name || '',
            localName: response.data.local?.name || '',
            subLocation: response.data.subLocation?.name || '',
            serviceName: response.data.service.shortName,
            date: checkoutInformation.date,
            time: checkoutInformation.time,
            professionalName: checkoutInformation.professional?.text || '',
            appointment: {
              appointmentId: appointmentId,
              clientId: response.data.client.idClient,
              name: `${formValues.name} ${formValues.lastname}`,
              email: formValues.email,
              phone: formValues.phone,
              dni: formValues.dni,
              formUrl: response.data.preAppointmentSurveyUrl,
            },
          },
        },
      });
    } catch (error) {
      handleErrorModal(
        error,
        (result) => {
          if (result.isConfirmed) {
            router.push({
              pathname: `/mas/service-detail/${checkoutInformation.sku}`,
            });
          }
        },
        () => {
        },
        {},
      );
    }
    setLoading(false);
  };

  const verifyResponse = (response) => {
    if (!response) {
      throw new Error(t('contact-information.form.default-error'));
    }

    if (response.respCode === 400 && response.glosa === t('contact-information.form.expected-error-400')) {
      throw new Error(t('contact-information.form.error-400'));
    }

    if (response.respCode !== 200) {
      throw new Error(response.glosa);
    }
  };

  const handleErrorModal = (error) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error,
      confirmButtonText: t('contact-information.form.error-button-text'),
      showConfirmButton: true,
      allowOutsideClick: false,
      showCancelButton: false,
      width: '750px',
      imageWidth: '80px',
      confirmButtonColor: '#aad500',
      customClass: {
        confirmButton: 'confirm-button-class error-button-modal',
        popup: 'popup-class',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        router.push({
          pathname: `/mas/service-detail/${checkoutInformation.sku}`,
        });
      }
    });
  };

  const getCheckItems = () => {
    const countryCode = getCountry();

    if (countryCode === 'es_CL') {
      return (
        <>
          <div className="check-form__item check">
            <CheckboxInput
              id="checkTerms"
              label={
                <>
                  Acepto los
                  <a
                    href="_"
                    rel="noopener noreferrer"
                    onClick={(e) => handleSeeTerms(e, appointmentsTerms?.content || '')}
                    className="privacy-links"
                  >
                    {' '}
                    términos y condiciones{' '}
                  </a>
                  y
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={appointmentsTerms?.link2 || ''}
                    className="privacy-links"
                  >
                    {' '}
                    política de privacidad{' '}
                  </a>
                  de Falabella Retail.
                </>
              }
              handleCheck={(value) =>
                setFormValue((form) => ({
                  ...form,
                  checkTerms: value,
                }))
              }
              displayError={formErrors.checkTerms}
              errorMessage="Acepta los Términos y Condiciones."
            />
          </div>

          <div className="check-form__item check">
            <CheckboxInput
              id="checkPromo"
              label={
                <>
                  Acepto ser parte del
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={cmrpointsTerms?.link1 || ''}
                    className="privacy-links"
                  >
                    {' '}
                    programa CMR Puntos{' '}
                  </a>
                  y con ello recibir novedades y ofertas personalizadas de las Empresas Falabella, donde podré darme de
                  baja cuando quisiera según la
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={cmrpointsTerms?.link2 || ''}
                    className="privacy-links"
                  >
                    {' '}
                    Política de Privacidad.
                  </a>
                </>
              }
              handleCheck={(value) =>
                setFormValue((form) => ({
                  ...form,
                  checkPromotion: value,
                }))
              }
            />
          </div>

          {benefitTerms && !!Object.keys(benefitTerms).length && checkoutInformation.sku === BENEFIT_SKU && (
            <div className="check-form__item check">
              <CheckboxInput
                id="checkBenefit"
                label={
                  <>
                    Acepto los{' '}
                    <a
                      href="_"
                      rel="noopener noreferrer"
                      onClick={(e) => handleSeeTerms(e, benefitTerms.content || '')}
                      className="privacy-links"
                    >
                      Términos y condiciones{' '}
                    </a>
                    para inscribirme al programa de fidelización Pink Perks de Benefit Cosmetics
                  </>
                }
                handleCheck={(value) =>
                  setFormValue((form) => ({
                    ...form,
                    checkBenefit: value,
                  }))
                }
              />
            </div>
          )}
          <br />
        </>
      );
    } else if (countryCode === 'es_PE') {
      return (
        <>
          <div className="check-form__item check">
            <CheckboxInput
              id="checkTerms"
              label={
                <>
                  Acepto los
                  <a
                    href="_"
                    rel="noopener noreferrer"
                    onClick={(e) => handleSeeTerms(e, appointmentsTerms?.content || '')}
                    className="privacy-links"
                  >
                    {' '}
                    términos y condiciones{' '}
                  </a>
                  y
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={appointmentsTerms?.link2 || ''}
                    className="privacy-links"
                  >
                    {' '}
                    política de privacidad{' '}
                  </a>
                  de Falabella Retail.
                </>
              }
              handleCheck={(value) =>
                setFormValue((form) => ({
                  ...form,
                  checkTerms: value,
                }))
              }
              displayError={formErrors.checkTerms}
              errorMessage="Acepta los Términos y Condiciones."
            />
          </div>

          <div className="check-form__item check">
            <CheckboxInput
              id="checkPromo"
              label={
                <>
                  Acepto los
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={cmrpointsTerms?.link1 || ''}
                    className="privacy-links"
                  >
                    {' '}
                    Términos y Condiciones{' '}
                  </a>
                  para acceder al programa CMR Puntos
                </>
              }
              handleCheck={(value) =>
                setFormValue((form) => ({
                  ...form,
                  checkPromotion: value,
                }))
              }
            />
          </div>
        </>
      );
    } else if (countryCode === 'es_CO') {
      return (
        <>
          <div className="check-form__item check">
            <CheckboxInput
              id="checkTerms"
              label={
                <>
                  Acepto los
                  <a
                    href="_"
                    rel="noopener noreferrer"
                    onClick={(e) => handleSeeTerms(e, appointmentsTerms?.content || '')}
                    className="privacy-links"
                  >
                    {' '}
                    términos y condiciones{' '}
                  </a>
                  y autorizo el
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={appointmentsTerms?.link2 || ''}
                    className="privacy-links"
                  >
                    {' '}
                    Tratamiento de mis Datos Personales
                  </a>
                  .
                </>
              }
              handleCheck={(value) =>
                setFormValue((form) => ({
                  ...form,
                  checkTerms: value,
                }))
              }
              displayError={formErrors.checkTerms}
              errorMessage="Acepta los Términos y Condiciones."
            />
          </div>

          <div className="check-form__item check">
            <CheckboxInput
              id="checkPromo"
              label={
                <>
                  Quiero acumular los CMR Puntos de mis compras y tener la posibilidad de redimirlos en miles de
                  productos. Autorizo el
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={cmrpointsTerms?.link1 || ''}
                    className="privacy-links"
                  >
                    {' '}
                    Tratamiento de mis Datos Personales{' '}
                  </a>
                  y
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={cmrpointsTerms?.link2 || ''}
                    className="privacy-links"
                  >
                    {' '}
                    Reglamento del Programa CMR Puntos.
                  </a>
                </>
              }
              handleCheck={(value) =>
                setFormValue((form) => ({
                  ...form,
                  checkPromotion: value,
                }))
              }
            />
          </div>
        </>
      );
    }

    return <></>;
  };

  return (
    <div className="check-form__wrapper">
      <div className="check-form__content">
        <div className="check-form__item">
          <InputText
            label={t('contact-information.form.name-label')}
            id="check-form__name"
            placeholder={t('contact-information.form.name-placeholder')}
            errorMessage={t('contact-information.form.name-error')}
            handleChange={(value) =>
              setFormValue((form) => ({
                ...form,
                name: value,
              }))
            }
            lengthToBeValidated={1}
            regex={onlyTextWithSpaces}
            tooltipMessage={t('contact-information.form.name-tooltip')}
            displayError={formErrors.name}
          />
        </div>

        <div className="check-form__item">
          <InputText
            label={t('contact-information.form.last-name-label')}
            id="check-form__lastname"
            placeholder={t('contact-information.form.last-name-placeholder')}
            errorMessage={t('contact-information.form.last-name-error')}
            handleChange={(value) =>
              setFormValue((form) => ({
                ...form,
                lastname: value,
              }))
            }
            lengthToBeValidated={1}
            regex={onlyTextWithSpaces}
            tooltipMessage={t('contact-information.form.last-name-tooltip')}
            displayError={formErrors.lastname}
          />
        </div>

        <div className="check-form__item">
          <InputText
            label={t('contact-information.form.dni-label')}
            id="check-form__dni"
            placeholder={t('contact-information.form.dni-placeholder')}
            errorMessage={t('contact-information.form.dni-error')}
            handleChange={(value) =>
              setFormValue((form) => ({
                ...form,
                dni: value,
              }))
            }
            lengthToBeValidated={1}
            tooltipMessage={t('contact-information.form.dni-tooltip')}
            displayError={formErrors.dni}
          />
        </div>

        <div className="check-form__item">
          <InputText
            label={t('contact-information.form.phone-label')}
            id="check-form__phone"
            placeholder={t('contact-information.form.phone-placeholder')}
            errorMessage={t('contact-information.form.phone-error')}
            handleChange={(value) =>
              setFormValue((form) => ({
                ...form,
                phone: value,
              }))
            }
            tooltipMessage={t('contact-information.form.phone-tooltip')}
            displayError={formErrors.phone}
          />
        </div>

        <div
          className={`check-form__item ${
            checkoutInformation?.placeOfDelivery?.toUpperCase() !== 'DOMICILIO' ? 'hidden' : ''
          }`}
        >
          <InputText
            label={t('contact-information.form.address-label')}
            id="check-form__address"
            placeholder={t('contact-information.form.address-placeholder')}
            errorMessage={t('contact-information.form.address-error')}
            handleChange={(value) =>
              setFormValue((form) => ({
                ...form,
                address: value,
              }))
            }
            lengthToBeValidated={1}
            regex={onlyLettersAndNumbers}
            tooltipMessage={t('contact-information.form.address-tooltip')}
            displayError={formErrors.address}
          />
        </div>

        <div className="check-form__item">
          <InputText
            label={t('contact-information.form.email-label')}
            id="check-form__email"
            placeholder={t('contact-information.form.email-placeholder')}
            errorMessage={t('contact-information.form.email-error')}
            handleChange={(value) =>
              setFormValue((form) => ({
                ...form,
                email: value,
              }))
            }
            lengthToBeValidated={1}
            regex={emailRegex}
            tooltipMessage={t('contact-information.form.email-tooltip')}
            displayError={formErrors.email}
          />
        </div>

        <div className={`check-form__item ${!checkoutInformation.requirePrevSale ? 'hidden' : ''}`}>
          <InputText
            label={t('contact-information.form.ticket-label')}
            id="check-form__ticket"
            placeholder={t('contact-information.form.ticket-placeholder')}
            errorMessage={t('contact-information.form.ticket-error')}
            handleChange={(value) =>
              setFormValue((form) => ({
                ...form,
                ticket: value,
              }))
            }
            tooltipMessage={t('contact-information.form.ticket-tooltip')}
            displayError={formErrors.ticket}
          />
        </div>

        {getCheckItems()}

        <div className="check-form__item check-form__item--center">
          <button id="confirm-schedule-btn" className="check-form__form-btn" onClick={handleSubmit} disabled={loading}>
            {t('contact-information.form.confirm-button-text')}
          </button>
        </div>
      </div>

      <ModalTerms show={displayModalTerms} onHide={() => setDisplayModalTerms(false)} content={modalTermsText} />
    </div>
  );
};

export default CheckoutForm;
