import React from 'react'
import { displayServicePriceWithPrefix } from '../../../utils/service'
import './style.scss'

interface iProps {
  price: number,
  prefix?: string
}

const ServicePrice = ({ price, prefix = '' }: iProps) => {
  const buttonLabel = displayServicePriceWithPrefix(price, prefix)

  return (
    <span id='item-info-price' className={`service-price ${buttonLabel.length >= 20 ? 'service-price--big' : ''}`}>
      {buttonLabel}
    </span>
  )
}
export default ServicePrice
