import React from 'react';
import useCountry from 'hooks/countryHook';
import './styles.scss';

interface iProps {
  display?: boolean;
  isComplementary?: boolean;
}

const Title = ({ display = true, isComplementary = false }: iProps) => {
  const { t } = useCountry();
  return (
    <div className={`check-form-title ${display ? '' : 'check-form-title--hidden'}`}>
      <h2 className='check-form-title__text'>{t('contact-information.title')}</h2>
      <h3 className='check-form-title__subtitle'>
        {t(
          isComplementary
            ? 'contact-information.complementary-services-message'
            : 'contact-information.other-services-message'
        )}
      </h3>
    </div>
  );
};

export default Title;
