import React, { useState } from 'react'
import { MenuTypes } from './'
import LinkItem from './LinkItem'
import { Accordion } from 'react-bootstrap'
import SelectableItem from './SelectableItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import './DropDownItem.scss'

const DropDownItem = ({ item, handleCheck }) => {
    const [isCollapsed, setIsCollapsed] = useState(false)

    return (
        <li>
            <Accordion className='dropdown-item'>
                <Accordion.Toggle
                    className='dropdown-item__header'
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    eventKey={item.text}
                >
                    <span className='dropdown-item__title'>{item.text.toUpperCase()}</span>
                    <span className='dropdown-item__icon'>
                        <FontAwesomeIcon
                            icon={isCollapsed ? faChevronDown : faChevronRight}
                            size='lg'
                        />
                    </span>
                </Accordion.Toggle>
                <Accordion.Collapse className='dropdown-item__collapse' eventKey={item.text}>
                    <ul>
                        {item.values.map((option, index) => {
                            if (option.type === MenuTypes.LINK) {
                                return <LinkItem item={option} key={index} />
                            }
                            else if (option.type === MenuTypes.SELECTABLE) {
                                return <SelectableItem item={option} handleCheck={handleCheck} key={index} />
                            }

                            return false;
                        })}
                    </ul>
                </Accordion.Collapse>
            </Accordion>
        </li>
    )
}

export default DropDownItem
