import React, { useState, useEffect } from 'react';
import LinkItem from './LinkItem';
import DropDownItem from './DropDownItem';
import useCountry from 'hooks/countryHook';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './menu.scss';

export const MenuTypes = {
  LINK: 'link',
  DROP: 'dropdown',
  SELECTABLE: 'selectable',
};

export interface ItemValue {
  type: string;
  name: string;
  id: string;
  selected: boolean;
}

export interface MenuItem {
  type: string;
  url: string;
  text: string;
  values: ItemValue[];
  customClass?: string;
}

interface iProps {
  display: boolean;
  handleClose: () => void;
  title: string;
  items?: MenuItem[];
  handleChange?: (items: MenuItem[]) => void;
  eachUpdate?: (items: MenuItem[]) => void;
}

const Menu = ({ display, handleClose, handleChange, title, eachUpdate, items }: iProps) => {
  const { t } = useCountry();
  const [itemList, setItemList] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (items && items.length) {
      setItemList(items);
    }
  }, [items]);

  const handleCheck = (id, value) => {
    const list = itemList;

    list.forEach((l) => {
      l.values.map((item) => {
        if (item.id === id) {
          item.selected = value;
        }
        return item;
      });
    });

    setItemList(list);
    eachUpdate && eachUpdate(list);
  };

  const applyFilters = () => {
    handleChange && handleChange(itemList);
    handleClose();
  };

  const resetFilters = () => {
    handleChange && handleChange([]);
    handleClose();
  };

  return (
    <div className={`menu ${display ? '' : 'menu--hidden'}`}>
      <div className='menu__background' onClick={handleClose}></div>
      <div className='menu__list'>
        <div className='menu__head'>
          <span className='menu__title'>{title}</span>
          <span className='menu__close-button' onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} size='lg' />
          </span>
        </div>

        <ul className='menu__item-list'>
          {itemList.map((item, index) => {
            if (item.type === MenuTypes.LINK) {
              return <LinkItem item={item} key={index} />;
            } else if (item.type === MenuTypes.DROP) {
              return <DropDownItem item={item} key={index} handleCheck={handleCheck} />;
            }

            return false;
          })}
        </ul>

        {handleChange && (
          <div className='menu__filters'>
            <div className='menu__filters-clear-button'>
              <span onClick={resetFilters}>{t('pdp.filters.cleanFilters')}</span>
            </div>
            <div className='menu__filters-apply-button'>
              <button className='button button-primary button-primary-large' onClick={applyFilters}>
                {t('pdp.filters.applyText')}
              </button>
            </div>
          </div>
        )}
      </div>
      {display && <style>{`body { overflow: hidden;}`}</style>}
    </div>
  );
};

export default Menu;
