export const ChileCode = 'es_CL';
export const PeruCode = 'es_PE';
export const ColombiaCode = 'es_CO';
export const DEFAULT_COUNTRY = ChileCode;

export const getCountryByURL = () => {
  const hostName = window.location.hostname || '';

  const ChileanHost = process.env.REACT_APP_FRONT_HOST_CL;
  const PeruvianHost = process.env.REACT_APP_FRONT_HOST_PE;
  const ColombianHost = process.env.REACT_APP_FRONT_HOST_CO;

  switch (hostName) {
    case PeruvianHost:
      return PeruCode;
    case ColombianHost:
      return ColombiaCode;
    case ChileanHost:
    default:
      return ChileCode;
  }
};

export const getSavedCountry = (query = false) => {
  const showCountrySelector = (process.env.REACT_APP_SHOW_COUNTRY_SELECTOR || false) === "true";
  let country = DEFAULT_COUNTRY;
  const savedCountry = localStorage.getItem('country');

  if (savedCountry && typeof savedCountry === 'string' && savedCountry !== country && showCountrySelector) {
    country = savedCountry;
  } else {
    country = getCountryByURL();
  }

  // Exists country in query?
  const queryCountry = query && query.get('country');
  const codeRegex = /es_[A-Z]{2}/;
  if (queryCountry && typeof queryCountry === 'string' && codeRegex.test(queryCountry)) {
    localStorage.setItem('country', queryCountry);
    country = queryCountry;
  }

  return country;
};

export const getCountryHeader = () => {
  const country = getSavedCountry();

  let code = '';

  switch (country) {
    case PeruCode:
      code = 'PE';
      break;
    case ColombiaCode:
      code = 'CO';
      break;
    case ChileCode:
      code = 'CL';
      break;
    default:
      code = 'CL';
      break;
  }

  return code;
};

export const properties = {
  backendUrl: process.env.REACT_APP_BACKEND_IP_CL,
  token: '1053n',
};
