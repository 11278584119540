import React from 'react';
import NotFound from './containers/NotFound';
import Checkout from './containers/Checkout';
import SurveyPage from './containers/Survey';
import Services from './containers/LoadStructureServices';
import ProductDetailPage from './containers/ProductDetailPage';
import ContactInformation from './containers/ContactInformation';
import CancelAppointment from './containers/UpdateAppointment/Cancel';
import ConfirmAppointment from './containers/UpdateAppointment/Confirm';
import RescheduleAppointment from './containers/UpdateAppointment/Reschedule';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

const Routes = () => (
  <Router>
    <Switch>
      <Redirect path='/' to='/mas' exact />

      <Route path='/mas' exact component={Services} />
      <Route path='/mas/services' exact component={Services} />
      <Route path='/mas/service-detail/:id' exact component={ProductDetailPage} />
      <Route path='/mas/contact-information' exact component={ContactInformation} />
      <Route path='/mas/checkout' exact component={Checkout} />

      {/* Mail pages */}
      <Route path='/confirm-appointment' exact component={ConfirmAppointment} />
      <Route path='/cancel-appointment' exact component={CancelAppointment} />
      <Route path='/reschedule-appointment' exact component={RescheduleAppointment} />

      {/* Survey */}
      <Route path='/survey' exact component={SurveyPage} />

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
