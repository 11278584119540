import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useQuery from '../../hooks/useQuery';
import { getServiceDetails } from '../../api';
import { useHistory } from 'react-router-dom';
import useCountry from '../../hooks/countryHook';
import AlertSvg from '../../img/alert-error.svg';
import Layout from '../../components/layout/Layout';
import ScheduleModal from '../../components/modal/ScheduleModal';
import './styles.scss';

const defaultFilters = {
  province: '',
  store: '',
  subLocation: '',
  local: '',
};

const defaultServiceInformation = {
  sku: '',
  serviceName: '',
  locationName: '',
  storeName: '',
  subLocationName: '',
  localName: '',
  placeOfDelivery: '',
};

const Reschedule = (props) => {
  const { t } = useCountry();
  const query = useQuery();
  const router = useHistory();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('success');
  const [printedDate, setPrintedDate] = useState('--');
  const [printedTime, setPrintedTime] = useState('--');
  const [showCalendar, setShowCalendar] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [service, setService] = useState(null);
  const fullDateFormat = t('reschedule-appointment.full-date-format');
  const dateFormat = t('reschedule-appointment.date-format');
  const hourFormat = t('reschedule-appointment.hour-format');
  const hourFormatCalendar = t('reschedule-appointment.hour-format-calendar');

  const [serviceInformation, setServiceInformation] = useState(defaultServiceInformation);

  useEffect(() => {
    const getServiceData = async () => {
      const sku = query.get('sku');
      const data = await getServiceDetails(sku);

      if (data) {
        setService(data);
        setServiceInformation((old) => ({
          ...old,
          sku: data.sku,
          serviceName: data.shortName,
          placeOfDelivery: data.placeOfDelivery?.toUpperCase(),
        }));
      } else {
        setStatus('error');
      }
    };

    getServiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setModalFilters = () => {
      let tmpFilters = {
        ...filters,
        sku: service.sku,
        type: service.placeOfDelivery?.toUpperCase(),
        appointmentId: query.get('appId') || '',
        store: query.get('storeId') || '',
        subLocation: query.get('communeId') || '',
        local: query.get('localId') || '',
        professional: query.get('professional'),
      };
      setFilters(tmpFilters);
      setLoading(false);
    };

    const printData = async () => {
      let parsedData = {
        date: '--',
        time: '--',
      };
      let hasAnyProblem = false;

      const timeUnit = await query.get('timeUnit');
      if (timeUnit) {
        const datetime = await query.get('datetime');
        const from = await query.get('from');
        const to = await query.get('to');

        if ((timeUnit === 'D' || timeUnit === 'W') && from) {
          const fromDate = moment(from);
          const dailyHours = await query.get('dailyHours');

          if (fromDate.isValid() && dailyHours) {
            parsedData.date = fromDate.format(fullDateFormat);
            parsedData.time = dailyHours.replace('|', ' a ');
          } else {
            hasAnyProblem = true;
          }
        } else if (timeUnit === 'H' && datetime && from && to) {
          const datetimeObj = moment(datetime);

          if (datetimeObj.isValid()) {
            const fromDate = moment(`${datetimeObj.format(dateFormat)} ${from}`);
            const toDate = moment(`${datetimeObj.format(dateFormat)} ${to}`);

            if (fromDate.isValid() && toDate.isValid()) {
              parsedData.date = datetimeObj.format(fullDateFormat);
              parsedData.time = `${fromDate.format(hourFormat)} a ${toDate.format(hourFormat)}`;
            } else {
              hasAnyProblem = true;
            }
          } else {
            hasAnyProblem = true;
          }
        } else {
          hasAnyProblem = true;
        }
      } else {
        hasAnyProblem = true;
      }

      if (hasAnyProblem) {
        setStatus('error');
      } else {
        setModalFilters();
      }

      setPrintedDate(parsedData.date);
      setPrintedTime(parsedData.time);
    };

    if (service) {
      printData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  const handleReschedule = () => setShowCalendar(true);

  const handleCancel = () => router.push(`/cancel-appointment${props.location.search}`);

  const rescheduleSuccess = ({ date, time, professional }) => {
    const parsedDate = date ? moment(date).format(fullDateFormat) : '';
    let parsedTime = '';

    const timeUnit = query.get('timeUnit');
    const dailyHours = query.get('dailyHours');

    if (timeUnit === 'D' && dailyHours) {
      parsedTime = dailyHours.replace('|', ' a ');
    } else if (timeUnit === 'H') {
      const fromTime = moment(time.from);
      const toTime = moment(time.to);

      if (fromTime.isValid() && toTime.isValid()) {
        parsedTime = `${fromTime.format(hourFormatCalendar)} a ${toTime.format(hourFormatCalendar)}`;
      }
    }

    router.push({
      pathname: '/mas/checkout',
      state: {
        serviceInformation: {
          placeOfDelivery: service.placeOfDelivery,
          storeName: query.get('ubication') || '',
          localName: query.get('ubication') || '',
          subLocationName: query.get('ubication') || '',
          serviceName: service.shortName,
          date: parsedDate,
          time: parsedTime,
          professionalName: professional || '',
          appointment: {},
        },
      },
    });
  };

  if (loading) {
    return (
      <div className='update-appointment__wrapper'>
        <div className='update-appointment__card'>{t('reschedule-appointment.loading-text')}</div>
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className='update-appointment__card'>
        <img className='update-appointment__icon' src={AlertSvg} alt='Check' />
        <div className='update-appointment__text'>{t('reschedule-appointment.error-title')}</div>
        <div className='update-appointment__text'>{t('reschedule-appointment.error-subtitle')}</div>
      </div>
    );
  }

  return (
    <Layout>
      <ScheduleModal
        show={showCalendar}
        toggleShow={() => setShowCalendar(false)}
        filters={filters}
        service={service}
        history={router.history}
        serviceInformation={serviceInformation}
        appointmentId={query.get('appId') || ''}
        token={query.get('token') || ''}
        setRescheduleError={setStatus}
        rescheduleSuccess={rescheduleSuccess}
        isReschedule
      />

      <div className='upd-app__wrapper'>
        <h1 className='upd-app__title'>{t('reschedule-appointment.title')}</h1>
        <h2 className='upd-app__subtitle upd-app__subtitle--sm'>{t('reschedule-appointment.subtitle')}</h2>
        <h2 className='upd-app__subtitle upd-app__subtitle--sm'>{t('reschedule-appointment.information')}</h2>

        <div className='upd-app__details'>
          <div className='upd-app__details-title upd-app__details-title--center'>
            <span>{t('reschedule-appointment.details.title')}</span>
          </div>
          <div className='upd-app__details-content'>
            <div className='upd-app__details-section'>
              <span className='upd-app__details-section-title'>{t('reschedule-appointment.details.date')}</span>
              <span className='upd-app__details-section-value'>{printedDate}</span>
            </div>

            <div className='upd-app__details-section'>
              <span className='upd-app__details-section-title'>{t('reschedule-appointment.details.fantasyId')}</span>
              <span className='upd-app__details-section-value'>{query.get('fantasyId') || '--'}</span>
            </div>

            <div className='upd-app__details-section'>
              <span className='upd-app__details-section-title'>{t('reschedule-appointment.details.time')}</span>
              <span className='upd-app__details-section-value'>{printedTime}</span>
            </div>

            <div className='upd-app__details-section'>
              <span className='upd-app__details-section-title'>{t('reschedule-appointment.details.location')}</span>
              <span className='upd-app__details-section-value upd-app__details-section-value--capitalize'>
                {query.get('ubication')?.toLowerCase() || 'Online'}
              </span>
            </div>
          </div>
        </div>

        <div className='upd-app__buttons-wrapper'>
          <button className='upd-app__button upd-app__button--sucess' onClick={handleReschedule}>
            {t('reschedule-appointment.reschedule-button-text')}
          </button>
          <button className='upd-app__button upd-app__button--cancel' onClick={handleCancel}>
            {t('reschedule-appointment.cancel-button-text')}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Reschedule;
