import React, { useState, useEffect, useContext } from 'react';
import { getServiceDetails } from '../../api';
import useCountry from '../../hooks/countryHook';
import Layout from '../../components/layout/Layout';
import ServiceImage from '../../components/service/image';
import ServiceTitle from '../../components/service/title';
import ServiceForm from '../../components/service/pdpForm';
import ServiceContext from '../../contexts/serviceContext';
import ServiceDetails from '../../components/service/details';
import LoadBannerTop from '../../components/banner/BannerTop';
import Breadcrumb from '../../components/breadcrumbs/pdpBreadcrumb';
import ServiceDescription from '../../components/service/description';
import RecommendedServices from '../../components/service/recommendedServices';
import './styles.scss';

const ProductDetailPage = (props) => {
  const { t } = useCountry();
  const { history } = props;
  const sku = props.match.params.id;
  const [service, setService] = useState({});
  const [servicePrefix, setServicePrefix] = useState('');
  const [bannerProps, setBannerProps] = useState({});
  const [serviceFormulary, setServiceFormulary] = useState('');
  const [loading, setLoading] = useState(true);
  const { setCheckoutInformation, setSurveyAnswers } = useContext(ServiceContext);

  useEffect(() => {
    const getDetails = async (_sku) => {
      setLoading(true);
      const data = await getServiceDetails(_sku);
      if (data) {
        let serviceBanner = '';
        setService(data);

        if (data.pricePrefix && Object.keys(data.pricePrefix).length && data.pricePrefix.prefix) {
          setServicePrefix(data.pricePrefix.prefix);
        }

        if (data.imageBannerDesktopUrl || data.imageBannerMobileUrl || data.imageBannerUrl) {
          const tmpProps = {
            imageBannerDesktopUrl: data.imageBannerDesktopUrl || '',
            imageBannerMobileUrl: data.imageBannerMobileUrl || '',
            imageBannerUrl: data.imageBannerUrl || '',
          };
          setBannerProps(tmpProps);

          serviceBanner = tmpProps;
        } else {
          serviceBanner = '';
        }

        setCheckoutInformation({
          sku: sku,
          imageUrl: data.imageUrl,
          brand: data.brand,
          name: data.shortName,
          description: data.description,
          price: data.price,
          pricePrefix: data.pricePrefix,
          serviceBanner: serviceBanner,
          placeOfDelivery: data.placeOfDelivery,
          duration: data.duration,
          isMayday: data.maydayIntegrationIsActive || false,
          requirePrevSale: data.restrictSaleSchedule,
        });
        setSurveyAnswers(null);
        setServiceFormulary(data.preDateSurvey || '');
        setLoading(false);
      } else {
        history.push('/notfound');
      }
    };

    if (!Object.keys(service).length) {
      getDetails(sku);
    }
  }, [sku, history, service, setCheckoutInformation, setSurveyAnswers]);

  return (
    <Layout>
      <main className='pdp__wrapper'>
        {!loading && <LoadBannerTop bannerProps={bannerProps} />}

        <section className='section-card'>
          <nav>
            <Breadcrumb viewPlp={!!service.viewPlp} shortName={service.shortName || ''} />
          </nav>

          <div className='pdp__service-detail'>
            <ServiceImage url={service.imageUrl || ''} alt={service.shortName || ''} />

            <div className='pdp__service-detail-content'>
              <ServiceTitle
                brand={service.brand || ''}
                name={service.shortName || ''}
                sku={sku || ''}
                price={service.price || 0}
                pricePrefix={servicePrefix}
                displayPrice={service?.showPrice || false}
              />

              <ServiceDescription description={service.description || ''} />

              <div className='details-mobile'>
                <ServiceDetails content={service.detail || ''} />
              </div>
            </div>

            <span className='pdp__separator'></span>

            <div className='pdp__service-detail-form'>
              <ServiceForm service={service} history={history} serviceFormulary={serviceFormulary} />
            </div>
          </div>
        </section>

        {!!service.detail?.length && (
          <section className='section-card details'>
            <ServiceDetails content={service.detail || ''} />
          </section>
        )}

        {!!service.recommendedServices?.length && (
          <section className='section-recommended-services'>
            <RecommendedServices services={service.recommendedServices} />
          </section>
        )}

        {t('help.message').trim() ? (
          <section className='section-information'>
            <span>
              {t('help.message')} <b>{t('help.number')}</b>
            </span>
          </section>
        ) : (
          <br />
        )}
      </main>
    </Layout>
  );
};

export default ProductDetailPage;
