export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const onlyFirstLetterCap = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const parseServiceName = (serviceName) => {
  if (!serviceName) return ''

  const words = serviceName.split(" ");

  let newName = [];

  words.forEach((word) => {
    newName.push(`${onlyFirstLetterCap(word)}`);
  });

  newName = newName.join(" ");

  return newName;
};
