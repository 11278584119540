import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import useCountry from '../../hooks/countryHook';
import { ArrowLeft } from 'react-bootstrap-icons';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './pdpBreadcrumbStyles.scss';

const PdpBreadcrumb = ({ shortName, sku = '', isCheckout = false }) => {
  const { t } = useCountry();
  const router = useHistory();

  return (
    <Breadcrumb bsPrefix='breadcrumb-container' id='load-structure-sku-breadcrumb' as='div'>
      <ArrowLeft color='black' size={26} onClick={() => router.push('/mas')} style={{ cursor: 'pointer' }} />
      <Breadcrumb.Item bsPrefix='breadcrumb-items left-link' onClick={() => router.push('/mas')}>
        {t('breadcrumb.homeText')}
      </Breadcrumb.Item>

      <Breadcrumb.Item
        bsPrefix='breadcrumb-items left-link shortNameMobileBreadcrumb'
        onClick={() => (sku ? router.push(`/mas/service-detail/${sku}`) : '')}
        active={!isCheckout}
      >
        {shortName}
      </Breadcrumb.Item>

      {isCheckout && (
        <Breadcrumb.Item bsPrefix='breadcrumb-items active-link shortNameMobileBreadcrumb' active>
          {t('breadcrumb.checkoutText')}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

PdpBreadcrumb.propTypes = {
  shortName: PropTypes.string.isRequired,
  sku: PropTypes.string,
  isCheckout: PropTypes.bool,
};

PdpBreadcrumb.defaultProps = {};

export default PdpBreadcrumb;
