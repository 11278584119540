import React from 'react'
import './LinkItem.scss'

const LinkItem = ({ item }) => (
    <li className='link-item'>
        <a href={item.url} className='link-item__anchor'>
            <span className='link-item__text'>{item.text}</span>
        </a>
    </li>
)

export default LinkItem
