import React from "react";
import PropTypes from "prop-types";
import "../../styles/Falabella.css";
import "../../styles/FalabellaMedia.css";

const LoadReserveButton = ({ sku, history }) => {
  const url = `/mas/service-detail/${sku}`;
  const btnId = `btn${sku}`;

  const handleLocation = () => {
    history.push(url);
  };

  return (
    <>
      <div className="text-center pod-action">
        <button
          type="button"
          id={btnId}
          onClick={handleLocation}
          className="button button-primary button-primary-large"
        >
          AGENDAR
        </button>
      </div>
    </>
  );
};

LoadReserveButton.propTypes = {
  history: PropTypes.object.isRequired,
};

LoadReserveButton.defaultProps = {};

export default LoadReserveButton;
