import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getServiceDetails } from '../../../api';
import useCountry from '../../../hooks/countryHook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTag } from '@fortawesome/free-solid-svg-icons';
import { displayServicePriceWithPrefix } from '../../../utils/service';

import './styles.scss';

const defProps = {
  img: '',
  prefix: '',
  price: '',
  name: '',
};

const ExperiencesItem = ({ sku, mkid = 'MASCHECKOUT' }) => {
  const { t } = useCountry();
  const [loading, setLoading] = useState(true);
  const [itemProps, setItemProps] = useState(defProps);

  const wrapperStyle = {
    backgroundImage: `url(${itemProps.img})`,
  };

  const buttonUrl = `/mas/service-detail/${sku}?mkid=${mkid}`;

  useEffect(() => {
    const getData = () => {
      getServiceDetails(sku)
        .then((data) => {
          setItemProps({
            img: data.imageUrl,
            prefix: data.pricePrefix?.prefix || '',
            price: data.price,
            name: data.shortName,
          });
          setLoading(false);
        })
        .catch(() => {});
    };

    if (sku) {
      getData();
    }
  }, [sku]);

  if (loading) {
    return <></>;
  }

  return (
    <div style={wrapperStyle} className='experience-item__wrapper'>
      <div className='experience-item__body'>
        <div className='experience-item__name'>
          <span>{itemProps.name}</span>
        </div>

        <div className='experience-item__price'>
          <span>
            {itemProps.prefix === 'Gratis' ? (
              <FontAwesomeIcon className='experience-item__icon experience-item__icon--green' icon={faCheck} />
            ) : (
              <FontAwesomeIcon className='experience-item__icon experience-item__icon--orange' icon={faTag} />
            )}
            {displayServicePriceWithPrefix(itemProps.price, itemProps.prefix)}
          </span>
        </div>
      </div>

      <div className='experience-item__footer'>
        <a className='experience-item__button' href={buttonUrl}>
          <span>{t('checkout.experiences-item.title')}</span>
          <span className='experience-item__button-icon'>{'>'}</span>
        </a>
      </div>
    </div>
  );
};

ExperiencesItem.propTypes = {
  sku: PropTypes.string.isRequired,
};

export default ExperiencesItem;
